import styled from "styled-components";

const InputGroup = styled.div`
  display: grid;
  grid-gap: 1rem;
  grid-template-columns: ${props => props.columns};
  margin-bottom: 3rem;
  justify-items: ${props => props.items || "stretch"};
`;

export { InputGroup };
