import styled from "styled-components";

const TbfOverlay = styled.div`
  display: flex;
  flex-direction: column;
  background-color: white;
  padding: 0.7rem;
  width: 60px;
  justify-content: center;
  align-items: center;
  margin-top: 0.3rem;
`;

export default TbfOverlay;
