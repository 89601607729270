import React, { useState } from "react";
import Subscribe from "unstated-subscribe-hoc";

import Page from "../../ui/web/Page";
import HubStateContainer from "../../HubStateContainer";
import EntityGroup from "../../ui/EntityGroup";
import Typo from "../../ui/Typo";
import { Form } from "../../ui/Form";
//import { InputGroup } from "../../ui/Input";
import { Label, TextInput } from "../../ui/Form";
import Action from "../../ui/Action";
import ActionList from "../../ui/ActionList";

const staticContents = {
  title: "Passwort zurücksetzen (1/2)",
  description:
    "Bitte trage deine E-Mail ein, damit wir dir einen Link schicken können, mit welchem du dein Passwort zurücksetzen kannst.",
  form: {
    email: "E-Mail",
    submit_successful:
      "Es wurde eine Email mit dem Link für das Zurücksetzen des Passworts versandt.",
    submit_failed:
      "Zurücksetzen des Passworts fehlgeschlagen. E-Mail-Adresse ungültig.",
    submit_button: "Passwort anfordern"
  }
};

const initialFormData = { email: "" };

function PasswordResetForm({ context }) {
  const [formData, setFormData] = useState(initialFormData);
  const [submissionSuccessful, setSubmissionSuccessful] = useState(false);
  const [submissionError, setSubmissionError] = useState(false);

  const formDataChanged = (field, value) => {
    let newState = { ...formData };
    newState[field] = value;
    setFormData(newState);
  };

  const handleInputChange = event => {
    formDataChanged(event.target.name, event.target.value);
  };

  const isFormDataValid = () => {
    if (formData.email) {
      return true;
    }
  };

  const handleSubmit = event => {
    context
      .forgotPassword(formData.email)
      .then(r => {
        setSubmissionSuccessful(true);
      })
      .catch(e => {
        setSubmissionError(true);
        console.log(e.message);
      });

    event.preventDefault();
  };

  const renderResponse = (
    <div>
      <Typo size="1.2rem" weight="700">
        {staticContents.form.submit_successful}
      </Typo>
    </div>
  );

  const renderSubmissionError = (
    <div>
      <Typo color="red" margin="1rem 0;" size="0.8rem">
        {staticContents.form.submit_failed}
      </Typo>
    </div>
  );

  const renderFormInput = (
    <div>
      <Label htmlFor="email">{staticContents.form.email}</Label>
      <TextInput
        name="email"
        type="email"
        value={formData.email}
        onChange={handleInputChange}
        required
      />
      {submissionError && renderSubmissionError}
      <ActionList noBorder>
        <Action
          buttonStyle
          type="submit"
          value="Submit"
          disabled={!isFormDataValid()}
        >
          {staticContents.form.submit_button}
        </Action>
      </ActionList>
    </div>
  );

  const renderForm = (
    <Form onSubmit={handleSubmit}>
      {!submissionSuccessful ? renderFormInput : renderResponse}
    </Form>
  );

  return (
    <Page>
      <EntityGroup>
        <div>
          <Typo size="2rem">{staticContents.title}</Typo>
          <Typo margin="2rem 0 0 0" size="1.2rem">
            {staticContents.description}
          </Typo>
        </div>
        <div>{renderForm}</div>
      </EntityGroup>
    </Page>
  );
}

export default Subscribe(PasswordResetForm, { context: HubStateContainer });
