import styled, { css } from "styled-components";
import { NavLink } from "react-router-dom";

import ActionBase from "./primitives/ActionBase";

const LinkList = styled.div`
  padding: 3rem 0;
  width: 100%;
  > * {
    margin-left:0;
    margin-bottom: 1.5rem;
  }

  ${props =>
    props.inline &&
    css`
      display: flex;
      flex-direction: row;
      justify-content: flex-end;
      align-items: center;
      padding: 0;
      > * {
        margin-left: 2rem;
        margin-bottom: 0;
      }

    `}
`;

const Item = styled(NavLink)`
  ${ActionBase}

  :after {
    content: "";
    height: 3px;
    position: absolute;
    bottom: -5px;
    left: 0;
    width: 0;
    background: black;
    transition: ease-out 0.2s;
  }

  :hover:after {
    width: 100%;
  }

  &.active:after {
    width: 100%;
  }
`;

const Divider = styled.div`
  border-bottom: 1px solid gray;
  margin: 0.5rem;
  ${props =>
    props.inline &&
    css`
      border-bottom: none;
      border-left: 1px solid gray;
      margin: 0 0 0 2rem;
    `}
`;

LinkList.Item = Item;
LinkList.Divider = Divider;

export default LinkList;
