import React, { useState, useEffect } from "react";
import Subscribe from "unstated-subscribe-hoc";
import getThumb from "../../data/CloudinaryProvider";
import { NavLink } from "react-router-dom";

import {
  Trash2,
  Edit,
  MapPin,
  Plus,
  Tag,
  Sun,
  ChevronDown,
  ChevronUp,
  Check,
} from "react-feather";

import HubStateContainer from "../../HubStateContainer";

import Page from "../../ui/web/Page";
import Typo from "../../ui/Typo";
import Action from "../../ui/Action";
import ListView from "../../ui/ListView";
import ListViewItem from "../../ui/ListViewItem";
import Chip from "../../ui/Chip";
import Bar from "../../ui/Bar";
import ChoiceChip from "../../ui/ChoiceChip";

import { GardenMap } from "./GardenMap";

import {
  FieldLocationDistrictsTyrol,
  FieldLocationDistrictsSouthTyrol,
} from "./GardenType";

const staticContents = {
  title: "Gemeinschaftsgärten",
  subheader:
    "Hier findest du einen Überblick über die gemeinschaftlichen Garteninitiativen in Tirol und Südtirol. Dein Projekt ist nicht dabei? Wir freuen uns, wenn Du Dich registrierst und es hinzufügst!",
};

const mapViewport = {
  width: "100%",
  height: "500px",
  latitude: 47.016,
  longitude: 10.642,
  zoom: 7,
};

function GardenView({ context, ...props }) {
  const [filter, setFilter] = useState({
    _sort: "updatedAt:desc",
  });

  const [filterViewVisible, setFilterViewVisible] = useState(false);

  useEffect(() => {
    async function queryItems() {
      await context.syncList("gardens", filter);
    }

    queryItems();
  }, [context, filter]);

  const handleToggleFilterVisibility = () => {
    setFilterViewVisible(!filterViewVisible);
  };

  const handleFilterChange = (name, value, toggle) => () => {
    let newFilter = { ...filter };
    if (toggle) {
      newFilter[name] = !filter[name];
    } else {
      if (filter[name] !== value) {
        newFilter[name] = value;
      } else {
        delete newFilter[name];
      }
    }
    setFilter(newFilter);
  };

  const handleNewGardenClick = () => {
    if (context.state.user.isloggedin) {
      props.history.push("/gaerten/neu");
    } else {
      props.history.push("/login");
    }
  };

  const handleEditItemClick = (id) => (event) => {
    event.preventDefault();

    props.history.push(`/gaerten/${id}/bearbeiten`);
  };

  const handleRemoveItemClick = (id) => (event) => {
    event.preventDefault();

    context.update("gardens", id, { headerImage: null }).then((updatedType) => {
      context.remove("gardens", updatedType.id);
    });
  };

  const gardenLocations = context.state.gardens.items
    .filter((garden) => garden.locationLatLng.length > 0)
    .map((garden) => garden.locationLatLng);

  return (
    <Page
      header={{
        text: staticContents.title,
        subheader: staticContents.subheader,
        heroheader: false,
        overlay: true,
      }}
      customContent={
        <GardenMap
          mapViewport={mapViewport}
          gardenLocations={gardenLocations}
        />
      }
    >
      <ListView>
        <ListView.Header>
          <Bar>
            <Bar.Left>
              <Action onClick={() => handleToggleFilterVisibility()}>
                Bezirk
                {filterViewVisible === true ? (
                  <ChevronUp size="16px" />
                ) : (
                  <ChevronDown size="16px" />
                )}
              </Action>
            </Bar.Left>
            <Bar.Right>
              <Action buttonStyle onClick={() => handleNewGardenClick()}>
                Garten Hinzufügen
                <Plus size="16px" />
              </Action>
            </Bar.Right>
          </Bar>

          {filterViewVisible && (
            <ListView.Filter>
              <ListView.FilterProperties>
                {FieldLocationDistrictsTyrol.map((location) => (
                  <ChoiceChip
                    key={location}
                    selected={filter["locationDistrict"] === location}
                    onClick={handleFilterChange(
                      "locationDistrict",
                      location,
                      false
                    )}
                  >
                    {location}
                    {filter["locationDistrict"] === location && (
                      <Check size="16px" />
                    )}
                  </ChoiceChip>
                ))}
              </ListView.FilterProperties>
              <ListView.FilterProperties>
                {FieldLocationDistrictsSouthTyrol.map((location) => (
                  <ChoiceChip
                    key={location}
                    selected={filter["locationDistrict"] === location}
                    onClick={handleFilterChange(
                      "locationDistrict",
                      location,
                      false
                    )}
                  >
                    {location}
                    {filter["locationDistrict"] === location && (
                      <Check size="16px" />
                    )}
                  </ChoiceChip>
                ))}
              </ListView.FilterProperties>
            </ListView.Filter>
          )}
        </ListView.Header>
        <ListView.Items>
          {context.state.gardens.items.map((garden) => (
            <ListViewItem
              key={garden.id}
              link="true"
              as={NavLink}
              to={`/gaerten/${garden.id}`}
            >
              {garden.headerImage ? (
                <ListViewItem.Header
                  mediaUrl={getThumb(garden.headerImage.url, 900)}
                />
              ) : (
                <ListViewItem.Header />
              )}
              <ListViewItem.Content>
                <ListViewItem.Title>
                  <Typo family="Roboto Slab">{garden.name}</Typo>
                </ListViewItem.Title>
                <ListViewItem.PropertyList>
                  <Chip>
                    <Sun size="16px" />
                    Gartenplatz:&nbsp;{garden.patchAvailability}
                  </Chip>
                  <Chip>
                    <Tag size="16px" />
                    {garden.specialCategory}
                  </Chip>
                  <Chip highlighted>
                    <MapPin size="16px" />
                    {garden.locationCity}
                  </Chip>
                </ListViewItem.PropertyList>
                {context.state.user.isloggedin &&
                  context.isAuthorized(garden.user.id) && (
                    <ListViewItem.Actions>
                      <Action onClick={handleRemoveItemClick(garden.id)}>
                        Löschen
                        <Trash2 size="16px" />
                      </Action>
                      <Action onClick={handleEditItemClick(garden.id)}>
                        Bearbeiten
                        <Edit size="16px" />
                      </Action>
                    </ListViewItem.Actions>
                  )}
              </ListViewItem.Content>
            </ListViewItem>
          ))}
        </ListView.Items>
      </ListView>
    </Page>
  );
}

export default Subscribe(GardenView, { context: HubStateContainer });
