import styled from "styled-components";
import { ViewBreakpoint } from "./Theme";

const TypeSection = styled.div`
  margin-bottom: 2rem;
  display: grid;
  grid-gap: 1rem;
  @media ${ViewBreakpoint["m"]} {
    margin-bottom: 2rem;
    grid-template-columns: 1fr 3fr;
  }
`;

export default TypeSection;
