import React from 'react';
import { ThemeProvider } from 'styled-components';
import { Provider } from 'unstated';

import HubStateContainer from './HubStateContainer';

import { Theme, GlobalStyle } from './ui/Theme';
import Hub from './Hub';

const HubState = new HubStateContainer();

function HubContext () {
  return (
    <ThemeProvider theme={Theme}>
      <Provider inject={[HubState]}>
        <Hub />
        <GlobalStyle />
      </Provider>
    </ThemeProvider>
  );
}

export default HubContext;
