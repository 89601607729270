import React, { useState, useEffect } from "react";
import Subscribe from "unstated-subscribe-hoc";
import getThumb from "../../data/CloudinaryProvider";
import { NavLink } from "react-router-dom";

import HubStateContainer from "../../HubStateContainer";
import {
  MapPin,
  Tag,
  Plus,
  Edit,
  Trash2,
  ChevronDown,
  ChevronUp,
  Check,
} from "react-feather";

import Page from "../../ui/web/Page";
import Typo from "../../ui/Typo";
import ListView from "../../ui/ListView";
import ListViewItem from "../../ui/ListViewItem";
import Chip from "../../ui/Chip";
import ChoiceChip from "../../ui/ChoiceChip";
import TbfOverlay from "../../ui/custom/TbfOverlay";
import DateOverlay from "../../ui/DateOverlay";
import Action from "../../ui/Action";
import Bar from "../../ui/Bar";

import { FieldCategories } from "./EventType";

const staticContents = {
  title: "Veranstaltungen",
  subheader:
    "Hier findest du Termine zu Veranstaltungen in und zum Thema (Gemeinschafts-)Gärten. Du möchtest eine Veranstaltung empfehlen? Dann trage sie hier ein!",
};

function EventsView({ context, ...props }) {
  const today = new Date().toISOString().substring(0, 10);

  const [filter, setFilter] = useState({
    _sort: "dateStart:asc",
    dateStart_gte: today,
  });

  const [filterViewVisible, setFilterViewVisible] = useState(false);

  useEffect(() => {
    async function queryItems() {
      await context.syncList("events", filter);
    }

    queryItems();
  }, [context, filter]);

  const handleToggleFilterVisibility = () => {
    setFilterViewVisible(!filterViewVisible);
  };

  const handleFilterChange = (name, value, toggle) => () => {
    let newFilter = { ...filter };
    if (toggle) {
      newFilter[name] = !filter[name];
    } else {
      if (filter[name] !== value) {
        newFilter[name] = value;
      } else {
        delete newFilter[name];
      }
    }
    setFilter(newFilter);
  };

  const handleNewEventClick = () => {
    if (context.state.user.isloggedin) {
      props.history.push("/veranstaltungen/neu");
    } else {
      props.history.push("/login");
    }
  };

  const handleEditItemClick = (id) => (event) => {
    event.preventDefault();
    props.history.push(`/veranstaltungen/${id}/bearbeiten`);
  };

  const handleRemoveItemClick = (id) => (event) => {
    event.preventDefault();

    context.update("events", id, { headerImage: null }).then((updatedType) => {
      context.remove("events", id);
    });
  };

  return (
    <Page
      header={{
        text: staticContents.title,
        subheader: staticContents.subheader,
        heroheader: false,
      }}
    >
      <ListView>
        <ListView.Header>
          <Bar>
            <Bar.Left>
              <Action onClick={() => handleToggleFilterVisibility()}>
                Kategorie
                {filterViewVisible === true ? (
                  <ChevronUp size="16px" />
                ) : (
                  <ChevronDown size="16px" />
                )}
              </Action>
            </Bar.Left>
            <Bar.Right>
              <Action buttonStyle onClick={handleNewEventClick}>
                Veranstaltung Hinzufügen
                <Plus size="16px" />
              </Action>
            </Bar.Right>
          </Bar>
          {filterViewVisible && (
            <ListView.Filter>
              <ListView.FilterProperties>
                {FieldCategories.map((category) => (
                  <ChoiceChip
                    key={category}
                    selected={filter["category"] === category}
                    onClick={handleFilterChange("category", category, false)}
                  >
                    {category}
                    {filter["category"] === category && <Check size="16px" />}
                  </ChoiceChip>
                ))}
              </ListView.FilterProperties>
            </ListView.Filter>
          )}
        </ListView.Header>
        {context.state.events.items.length > 0 && (
          <ListView.Items>
            {context.state.events.items.map((event) => (
              <ListViewItem
                key={event.id.toString()}
                link="true"
                to={`/veranstaltungen/${event.id}`}
                as={NavLink}
              >
                {event.headerImage ? (
                  <ListViewItem.Header
                    mediaUrl={getThumb(event.headerImage.url, 900)}
                  />
                ) : (
                  <ListViewItem.Header />
                )}
                <ListViewItem.Overlay>
                  <DateOverlay rawDate={event.dateStart} />
                  {event.tbfHosted && <TbfOverlay>TBF</TbfOverlay>}
                </ListViewItem.Overlay>
                <ListViewItem.Content>
                  <ListViewItem.Title>
                    <Typo family="Roboto Slab">{event.name}</Typo>
                  </ListViewItem.Title>
                  <ListViewItem.Text>{event.description}</ListViewItem.Text>
                  <ListViewItem.PropertyList>
                    <Chip>
                      <Tag size="16px" />
                      {event.category}
                    </Chip>
                    <Chip highlighted>
                      <MapPin size="16px" />
                      {event.locationCity}
                    </Chip>
                  </ListViewItem.PropertyList>
                  {context.state.user.isloggedin &&
                    context.isAuthorized(event.user.id) && (
                      <ListViewItem.Actions>
                        <Action onClick={handleRemoveItemClick(event.id)}>
                          Löschen
                          <Trash2 size="16px" />
                        </Action>
                        <Action onClick={handleEditItemClick(event.id)}>
                          Bearbeiten
                          <Edit size="16px" />
                        </Action>
                      </ListViewItem.Actions>
                    )}
                </ListViewItem.Content>
              </ListViewItem>
            ))}
          </ListView.Items>
        )}
      </ListView>
    </Page>
  );
}

export default Subscribe(EventsView, { context: HubStateContainer });
