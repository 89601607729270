import styled from "styled-components";

import ActionList from "./ActionList";

const Label = styled.label`
  display: block;
  padding: 0;
  color: black;

`;

const TextArea = styled.textarea`
  display: block;
  position: relative;
  padding: 0;
  margin: 0.2rem 0 0.9rem 0;
  outline: 0;

  width: 100%;
  max-width: 100%;
  height: auto;
  overflow: auto;

  font-family: inherit;
  font-size: inherit;
  line-height: inherit;

  background: transparent;
  background-image: none;

  border: none;
  border-bottom: 1px solid #b3b3b3;
  color: inherit;
  box-shadow: none;

  :focus {
    border-color: black;
    border-width: 2px;
  }
`;

const TextInput = styled.input`
  display: block;
  position: relative;
  padding: 0;
  margin: 0.2rem 0 0.9rem 0;
  outline: 0;

  width: 100%;
  max-width: 100%;
  height: 1.8rem;
  overflow: visible;

  font-family: inherit;
  font-size: inherit;
  line-height: inherit;

  background: transparent;
  background-image: none;

  border: none;
  border-bottom: 1px solid #b3b3b3;
  color: inherit;
  box-shadow: none;

  :focus {
    border-color: black;
    border-width: 2px;
  }
`;

const Select = styled.select`
  display: block;
  position: relative;
  padding: 0;
  margin: 0.2rem 0 0.9rem 0;
  outline: 0;

  height: 1.8rem;
  width: ${props => (props.fullWidth ? "100%" : "auto")};

  font-family: inherit;
  font-size: inherit;
  line-height: inherit;

  background: transparent;
  background-image: none;

  border: none;
  border-bottom: 1px solid #b3b3b3;
  color: inherit;

  text-transform: none;

  :focus {
    border-color: black;
  }
`;

const Form = styled.form`
  width: 100%;
  background-color: #f5f5f5;
  padding: ${props => (props.inline ? "1.4rem" : "3rem")};
  margin-top: ${props => (props.inline ? "1rem" : "0")};
`;

const Header = styled.div`
  font-size: 2rem;
  margin-bottom: 4rem;
  color: #868686;
  font-weight: bold;
`;

const Contents = styled.div`
  display: grid;
  grid-template-columns: 2fr 1fr;
  grid-gap: 4rem;
  margin-bottom: 1rem;
`;

const PrimaryContent = styled.div`
  background-color: transparent;
`;

const SecondaryContent = styled.div`
  background-color: transparent;
`;

const Actions = styled(ActionList)`
  justify-content: flex-end;
  padding: 2rem 0 0 0;
  > * {
    margin-left: 1rem;
  }
`;

Form.Header = Header;
Form.Contents = Contents;
Form.PrimaryContent = PrimaryContent;
Form.SecondaryContent = SecondaryContent;
Form.Actions = Actions;

export { Label, TextArea, TextInput, Select, Form };
