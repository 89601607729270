const FieldSpecialCategory = [
  "Gemeinschaftsgarten",
  "Selbsterntefeld",
  "Therapiegarten",
  "Interkultureller Garten",
  "Urban Gardening",
  "Schulgarten",
  "Nachbarschaftsgarten",
  "Essbare Gemeinde" 
];

const FieldAccessKind = [
  "öffentlich zugänglich",
  "bestimmte Öffnungszeiten",
  "nur für Mitglieder",
  "nur nach Voranmeldung"
];

const FieldCultivationType = [
  "individuellen & gemeinschaftlichen Beeten",
  "individuellen Beeten",
  "Gemeinschaftsbeeten"
];

const FieldPatchAvailability = ["Verfügbar", "Nicht Verfügbar", "Auf Anfrage"];

const FieldLocationDistrict = [
  "Imst (IM)",
  "Innsbruck-Stadt (I)",
  "Innsbruck-Land (IL)",
  "Kitzbühel (KB)",
  "Kufstein (KU)",
  "Landeck (LA)",
  "Reutte (RE)",
  "Schwaz (SZ)",
  "Lienz (LZ)",
  "Wipptal",
  "Eisacktal",
  "Pustertal",
  "Überetsch-Unterland",
  "Bozen",
  "Salten-Schlern",
  "Burggrafenamt",
  "Vinschgau"
];

const FieldLocationDistrictsTyrol =  FieldLocationDistrict.slice(0,9);
const FieldLocationDistrictsSouthTyrol =  FieldLocationDistrict.slice(9,17);

const GardenType = {
  accessInformation: "",
  accessKind: "",
  allowLinking: false,
  audience: "",
  contact: "",
  costs: "",
  cultivationType: "",
  description: "",
  foundingYear: "",
  headerImage: null,
  links: [],
  locationCity: "",
  locationDistrict: "",
  locationLatLng: [],
  locationPlace: "",
  memberCount: "",
  name: "",
  operator: "",
  patchAvailability: "",
  patchSizeMax: "",
  patchSizeMin: "",
  registration: "",
  services: "",
  size: "",
  specialCategory: FieldSpecialCategory[0]
};

const numFields = [
  "foundingYear",
  "memberCount",
  "patchSizeMin",
  "patchSizeMax",
  "size"
];

const toRestFormat = gardenData => {
  numFields.forEach(field => {
    if (gardenData[field] === "") {
      gardenData[field] = null;
    }
  });
  return gardenData;
};

const fromRestFormat = gardenData => {
  const fieldsToRemove = ["id", "_id", "createdAt", "updatedAt", "__v"];
  fieldsToRemove.forEach(field => delete gardenData[field]);

  if (!Array.isArray(gardenData.links)) {
    gardenData.links = [];
  }

  if (!Array.isArray(gardenData.locationLatLng)) {
    gardenData.locationLatLng = [];
  }

  numFields.forEach(field => {
    if (gardenData[field] === null) {
      gardenData[field] = "";
    }
  });

  return gardenData;
};

export {
  FieldSpecialCategory,
  FieldAccessKind,
  FieldCultivationType,
  FieldPatchAvailability,
  FieldLocationDistrict,
  GardenType,
  toRestFormat,
  fromRestFormat,
  FieldLocationDistrictsTyrol,
  FieldLocationDistrictsSouthTyrol
};
