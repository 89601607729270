import styled, { css } from "styled-components";

const View = styled.div`

  ${props =>
    props.withAside &&
    css`
      display: grid;
      grid-gap: 4rem;
      grid-template-columns: 1fr;

      @media (min-width: 800px) {
        grid-template-columns: 2fr 1fr;
      }
    `}


`;

const Section = styled.div`
  margin-bottom: 1rem;
`;

const Divider = styled.div`
  border-top: 1px solid #d4d4d4;
  margin: 2rem 0;
`;

const SectionHeader = styled.div`
  font-size: 0.9rem;
  color: #7b7b7b;
  margin-bottom: 0.4rem;
`;

const TextArea = styled.div`
  white-space: pre-line;
`;

const SecondaryContent = styled.div`
  padding: 2rem;
  border: 1px solid #d4d4d4;
`;

View.Section = Section;
View.Divider = Divider;
View.TextArea = TextArea;
View.SectionHeader = SectionHeader;
View.SecondaryContent = SecondaryContent;

export default View;
