import React, { useState } from "react";
import Subscribe from "unstated-subscribe-hoc";

import HubStateContainer from "../../HubStateContainer";
import EntityGroup from "../../ui/EntityGroup";
import Typo from "../../ui/Typo";
import { Form } from "../../ui/Form";
//import { InputGroup } from "../../ui/Input";
import { Label, TextInput } from "../../ui/Form";
import Action from "../../ui/Action";
import ActionList from "../../ui/ActionList";
import Page from "../../ui/web/Page";

const staticContents = {
  title: "Passwort zurücksetzen (2/2)",
  description: "Neues Passwort eingeben und bestätigen.",
  form: {
    password: "Neues Passwort",
    passwordConfirmation: "Neues Passwort bestätigen",
    submit_successful: "Passwort erfolgreich zurückgesetzt.",
    submit_failed: "Zurücksetzen des Passworts fehlgeschlagen.",
    submit_button: "Passwort speichern"
  }
};

const initialFormData = { password: "", passwordConfirmation: "" };

function PasswordConfirmForm({ context, ...props }) {
  const [formData, setFormData] = useState(initialFormData);
  const [submissionSuccessful, setSubmissionSuccessful] = useState(false);
  const [submissionError, setSubmissionError] = useState(false);

  const params = new URLSearchParams(props.location.search);
  const code = params.get("code");

  const formDataChanged = (field, value) => {
    let newState = { ...formData };
    newState[field] = value;
    setFormData(newState);
  };

  const handleInputChange = event => {
    formDataChanged(event.target.name, event.target.value);
  };

  const isFormDataValid = () => {
    if (formData.password && formData.passwordConfirmation) {
      if (formData.password === formData.passwordConfirmation) {
        return true;
      }
    }
  };

  const handleSubmit = event => {
    context
      .resetPassword(code, formData.password, formData.passwordConfirmation)
      .then(r => {
        setSubmissionSuccessful(true);
      })
      .catch(e => {
        setSubmissionError(true);
        console.log(e.message);
      });

    event.preventDefault();
  };

  const renderResponse = (
    <div>
      <Typo size="1.2rem" weight="700">
        {staticContents.form.submit_successful}
      </Typo>
    </div>
  );

  const renderSubmissionError = (
    <div>
      <Typo color="red" margin="1rem 0;" size="0.8rem">
        {staticContents.form.submit_failed}
      </Typo>
    </div>
  );

  const renderFormInput = (
    <div>
      <Label htmlFor="password">{staticContents.form.password}</Label>
      <TextInput
        name="password"
        type="password"
        value={formData.password}
        onChange={handleInputChange}
        required
      />
      <Label htmlFor="passwordConfirmation">
        {staticContents.form.passwordConfirmation}
      </Label>
      <TextInput
        name="passwordConfirmation"
        type="password"
        value={formData.passwordConfirmation}
        onChange={handleInputChange}
        required
      />
      {submissionError && renderSubmissionError}
      <ActionList noBorder>
        <Action
          buttonStyle
          type="submit"
          value="Submit"
          disabled={!isFormDataValid()}
        >
          {staticContents.form.submit_button}
        </Action>
      </ActionList>
    </div>
  );

  const renderForm = (
    <Form onSubmit={handleSubmit}>
      {!submissionSuccessful ? renderFormInput : renderResponse}
    </Form>
  );

  return (
    <Page>
      <EntityGroup>
        <div>
          <Typo size="2rem">{staticContents.title}</Typo>
          <Typo margin="2rem 0 0 0" size="1.2rem">
            {staticContents.description}
          </Typo>
        </div>
        <div>{renderForm}</div>
      </EntityGroup>
    </Page>
  );
}

export default Subscribe(PasswordConfirmForm, { context: HubStateContainer });
