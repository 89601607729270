import styled from "styled-components";

const Chip = styled.div`
  display: flex;
  flex-direction: row;
  align-items: center;

  font-size: inherit;
  font-weight: ${props => props.highlighted && "bold"};
  color: ${props => (props.highlighted ? "#93BD6B" : "inherit")};
  line-height: 1.5;
  text-transform: uppercase;
  > svg {
    margin-right: 0.3rem;
  }
`;

export default Chip;
