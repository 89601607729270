import React, { useEffect } from "react";
import { MapPin, Tag, Sun, Home } from "react-feather";
import Subscribe from "unstated-subscribe-hoc";
import getThumb from "../../data/CloudinaryProvider";

import HubStateContainer from "../../HubStateContainer";

import Page from "../../ui/web/Page";
import View from "../../ui/View";
import Chip from "../../ui/Chip";
import ActionLinkText from "../../ui/ActionLinkText";

function GardenDetailView(props) {
  const id = props.match.params.id;
  //const [isLoaded, setIsLoaded] = useState(false);

  useEffect(() => {
    //setIsLoaded(false);
    props.context.sync("gardens", id).then(() => {
      console.log("Garden Loaded:", props.context.state.gardens.selectedItem);
      //setIsLoaded(true);
    });

    return () => {
      props.context.unsync("gardens");
    };
  }, [props.context, id]);

  const convertDate = dateData => {
    const date = new Date(dateData);
    return date.toLocaleDateString();
  };

  const {
    context: {
      state: {
        gardens: { selectedItem }
      }
    }
  } = props;

  //Gartenplatz:&nbsp;
  const chips = [
    {
      label: `Gartenplatz:${selectedItem.patchAvailability}`,
      icon: <Sun size="16px" />
    },
    { label: selectedItem.specialCategory, icon: <Tag size="16px" /> },
    {
      label: selectedItem.locationCity,
      icon: <MapPin size="16px" />,
      highlighted: true
    }
  ];

  if (selectedItem.locationPlace) {
    chips.push({
      label: selectedItem.locationPlace,
      icon: <Home size="16px" />,
      highlighted: true
    });
  }

  return (
    <Page
      header={{
        text: selectedItem.name,
        bgimg:selectedItem.headerImage ? getThumb(selectedItem.headerImage.url, 1920) : null,
        overlay:true,
        heroheader:true,
      }}
      chips={chips}
    >
      <View withAside>
        <div>
          <View.Section>
            <View.TextArea>{selectedItem.description}</View.TextArea>
          </View.Section>

          {(selectedItem.contact || selectedItem.operator) && (
            <View.Section>
              <View.SectionHeader>
                <Chip>Kontakt</Chip>
              </View.SectionHeader>
              <div>{selectedItem.operator}</div>
              <div>{selectedItem.contact}</div>
            </View.Section>
          )}

          <View.Section>
            <View.SectionHeader>
              <Chip>Zugang</Chip>
            </View.SectionHeader>
            <div>{selectedItem.accessKind}</div>
            <div>{selectedItem.accessInformation}</div>
          </View.Section>

          {selectedItem.costs && (
            <View.Section>
              <View.SectionHeader>
                <Chip>Kosten</Chip>
              </View.SectionHeader>
              <div>{selectedItem.costs}</div>
            </View.Section>
          )}

          {selectedItem.registration && (
            <View.Section>
              <View.SectionHeader>
                <Chip>Anmeldung</Chip>
              </View.SectionHeader>
              <div>{selectedItem.registration}</div>
            </View.Section>
          )}

          {selectedItem.links && selectedItem.links.length > 0 && (
            <View.Section>
              <View.SectionHeader>
                <Chip>Links</Chip>
              </View.SectionHeader>
              {selectedItem.links.map(link => (
                <div key={link.description}>
                  <ActionLinkText href={link.url}>
                    {link.description}
                  </ActionLinkText>
                </div>
              ))}
            </View.Section>
          )}
        </div>

        <View.SecondaryContent>
          <View.Section>
            <View.SectionHeader>
              <Chip>Anbauart</Chip>
            </View.SectionHeader>
            <div>{selectedItem.cultivationType}</div>
          </View.Section>

          {(selectedItem.patchSizeMin || selectedItem.patchSizeMax) && (
            <View.Section>
              <View.SectionHeader>
                <Chip>Parzellengröße</Chip>
              </View.SectionHeader>
              <div>
                {selectedItem.patchSizeMin}m²
                {selectedItem.patchSizeMin && selectedItem.patchSizeMax && (
                  <span>&nbsp;-&nbsp;</span>
                )}
                {selectedItem.patchSizeMax}m²
              </div>
            </View.Section>
          )}

          <View.Divider />

          {selectedItem.foundingYear && (
            <View.Section>
              <View.SectionHeader>
                <Chip>Gründungsjahr</Chip>
              </View.SectionHeader>
              <div>{selectedItem.foundingYear}</div>
            </View.Section>
          )}

          {selectedItem.memberCount && (
            <View.Section>
              <View.SectionHeader>
                <Chip>Mitglieder</Chip>
              </View.SectionHeader>
              <div>{selectedItem.memberCount}</div>
            </View.Section>
          )}

          {selectedItem.size && (
            <View.Section>
              <View.SectionHeader>
                <Chip>Flächengröße</Chip>
              </View.SectionHeader>
              <div>{selectedItem.size}</div>
            </View.Section>
          )}

          {(selectedItem.foundingYear ||
            selectedItem.memberCount ||
            selectedItem.size) && <View.Divider />}

          {selectedItem.audience && (
            <View.Section>
              <View.SectionHeader>
                <Chip>Zielgruppe</Chip>
              </View.SectionHeader>
              <div>{selectedItem.audience}</div>
            </View.Section>
          )}

          {selectedItem.services && (
            <View.Section>
              <View.SectionHeader>
                <Chip>Besondere Angebote</Chip>
              </View.SectionHeader>
              <div>{selectedItem.services}</div>
            </View.Section>
          )}

          {(selectedItem.audience || selectedItem.services) && <View.Divider />}

          <View.Section>
            <View.SectionHeader>
              <Chip>Zuletzt aktualiert</Chip>
            </View.SectionHeader>
            <div>{convertDate(selectedItem.updatedAt)}</div>
          </View.Section>
        </View.SecondaryContent>
      </View>
    </Page>
  );
}

export default Subscribe(GardenDetailView, { context: HubStateContainer });