import styled from "styled-components";
import { ViewBreakpoint } from "./Theme";

const Header = styled.div`
  font-size: 0.9rem;
  margin: 1rem 0;
`;

const Items = styled.div`
  display: grid;
  grid-gap: 1rem;
  @media ${ViewBreakpoint["s"]} {
    grid-template-columns: repeat(2, minmax(0, 1fr));
  }
  @media ${ViewBreakpoint["m"]} {
    grid-template-columns: repeat(3, minmax(0, 1fr));
  }
`;

const Footer = styled.div`
  margin: 1rem 0;
`;

const Empty = styled.div`
  display: flex;
  flex-direction: column;
  align-items: center;
  justify-content: center;
  height: 100px;
  color: #929292;
  border: 1px solid #c3c3c3;
`;

const ListView = styled.div`
  width: 100%;
`;

const Filter = styled.div`
  margin: 2rem 0;
`;

const FilterProperties = styled.div`
  display: flex;
  flex-wrap: wrap;
  gap: 1rem 2rem;
`;

ListView.Items = Items;
ListView.Header = Header;
ListView.Footer = Footer;
ListView.Empty = Empty;
ListView.Filter = Filter;
ListView.FilterProperties = FilterProperties;

export default ListView;
