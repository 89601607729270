import React from "react";

import Page from "../../ui/web/Page";
import EntityGroup from "../../ui/EntityGroup";
import Typo, { Paragraph, Header, Header2 } from "../../ui/Typo";
import ActionLinkText from "../../ui/ActionLinkText";

const headerText = "Impressum";

function ImprintView() {
  return (
    <Page header={{ text: headerText, heroheader: false }}>
      <EntityGroup ratio="1-2">
        <div>
          <Paragraph>Für den Inhalt verantwortlich</Paragraph>
          <Header>Tiroler Bildungsforum - Verein für Kultur und Bildung</Header>

          <Paragraph>
            Sillgasse 8/2 6020 Innsbruck <br />
            Montag bis Freitag 8 bis 12 Uhr <br />
            T +43 (0) 512 581465 <br />
            F +43 (0) 512 581465 - DW 15 <br />
            <ActionLinkText href="mailto:tiroler.bildungsforum@tsn.at">
              tiroler.bildungsforum@tsn.at
            </ActionLinkText>{" "}
            <br />
            DVR-Nr. 0692221, ZVR 234248038
          </Paragraph>

          <Typo>Vereinsvorstand</Typo>
          <Paragraph>Obfrau Dr. Bettina Ellinger</Paragraph>

          <Paragraph>
            Allgemeine Geschäftsbedingungen Unsere Allgemeinen
            Geschäftsbedingungen finden Sie{" "}
            <ActionLinkText href="https://tiroler-bildungsforum.at/wp-content/uploads/2020/04/AGB-Tiroler-Bildungsforum.pdf">
              hier als Download
            </ActionLinkText>
          </Paragraph>
        </div>
        <div>
          <Header>Vereinszweck</Header>
          <Paragraph>
            Das Tiroler Bildungsforum ist eine Einrichtung des öffentlichen
            Bildungswesens mit dem Ziel, Angebote im Bereich der
            Erwachsenenbildung und Kulturarbeit zu schaffen, diese über
            verschiedene Veranstaltungsformen und Aktivitäten umfassend zu
            transportieren und die Erwachsenenbildung generell durch Anregungen
            und konkrete Mitwirkung zu fördern. Das Tiroler Bildungsforum (kurz:
            TBF) ist nicht auf Gewinn ausgerichtet. Es ist gemeinnützig und
            bezweckt Durchführung, Ausbau und Förderung von
            (Erwachsenen)Bildung, Chronikwesen, Kunst und Kultur in Tirol. Im
            Mittelpunkt der Vereinstätigkeit steht die regionale und lokale
            Bildungs- und Kulturarbeit.
          </Paragraph>
          <Header>Datenschutz</Header>
          <Header2>Datenschutzerklärung Tiroler Bildungsforum</Header2>
          <Paragraph>
            Die folgende Information zur Datenschutzerklärung besagt, dass wir
            Ihre Daten nur zur Zusendung von Veranstaltungshinweisen verwenden.
            Sie können jederzeit per Mail oder postalisch veranlassen, dass Sie
            keine weiteren Zusendungen mehr erhalten. Information gemäß Artikel
            13 Datenschutz-Grundverordnung
          </Paragraph>
          <Header2>Datenverarbeitung </Header2>
          <Paragraph>
            Wir verarbeiten Ihre personenbezogenen Daten, zum Zweck der
            Durchführung von Veranstaltungen und stützen uns dabei auf die
            Erfüllung unserer vertraglichen Pflichten (Artikel 6 Abs. 1 litera b
            DSGVO). Weiters verarbeiten wir Ihre personenbezogenen Daten zum
            Zweck der Zusendung von Informationen und Veranstaltungseinladungen
            und stützen uns dabei auf Ihre Einwilligung (Artikel 6 Abs. 1 litera
            a DSGVO).
          </Paragraph>
          <Header2>Datenübermittlung</Header2>
          <Paragraph>
            Ihre Daten werden nur dann an dritte Personen weitergegeben, wenn
            dies im Einzelfall zur Erfüllung gesetzlicher Pflichten erforderlich
            ist. Wenn Sie uns Ihre Einwilligung zur Sendung von Newslettern
            erteilen, werden wir Ihren Namen und Emailadresse an unseren
            Email-Newsletterdienstleister („Mailchimp“) weiterleiten.
          </Paragraph>
          <Header2>Hinweis</Header2>
          <Paragraph>
            Ihnen stehen die Rechte auf Auskunft, Berichtigung, Einschränkung,
            Löschung sowie Datenübertragbarkeit zu. Sie können diese Rechte
            unter der Emailadresse tiroler.bildungsforum@tsn.at oder postalisch
            bei Tiroler Bildungsforum, Sillgasse 8/2, 6020 Innsbruck geltend
            machen.
          </Paragraph>
          <Paragraph>
            Wenn Sie der Meinung sind, dass die Verarbeitung Ihrer Daten gegen
            die DSGVO oder eine andere datenschutzrelevante Vorschrift verstößt,
            steht es Ihnen frei, bei der Datenschutzbehörde{" "}
            <ActionLinkText href="https://www.dsb.gv.at/">
              (https://www.dsb.gv.at/)
            </ActionLinkText>{" "}
            Beschwerde zu erheben.
          </Paragraph>
          <Header2>
            Einwilligungserklärung gemäß Art. 6 Abs 1 lit. a DSGVO
          </Header2>
          <Paragraph>
            Ich bin damit einverstanden, dass mir das Tiroler Bildungsforum
            Newsletter zusendet und erkläre meine Einwilligung, dass zu diesem
            Zweck meine angegebenen personenbezogenen Daten (Name,
            E-Mail-Adresse) an den Newsletterdienstleister „Mailchimp“
            übermitteln werden. Mailchimp hat sich zur Einhaltung des
            Privacy-Shield-Abkommens verpflichtet.
          </Paragraph>
          <Header2>
            Datenschutzhinweise hinsichtlich der Herstellung und Verwendung von
            Foto- und/oder Videoaufnahmen
          </Header2>
          <Paragraph>
            Die Fotos und/oder Videos dienen ausschließtlich der
            Öffentlichkeitsarbeit des Tiroler Bildungsforums. Die Verarbeitung
            von Fotos und/oder Videos (Erhebung, Speicherung und Weitergabe an
            Dritte erfolgt aufgrund ausdrücklicher Einwilligung des/der
            Personensorgeberechtigten bzw. des/der Betroffenen, mithin gemäß
            Art. 6 Abs. 1 Buchstabe a DSGVO. Die Veröffentlichung ausgewählter
            Bilddateien in (Print)Publikationen des/der Veranstalters/-in sowie
            auf deren Homepage /Facebookaccount o.ä. ist für die
            Öffentlichkeitsarbeit des/der Veranstalters/-in erforderlich und
            dient damit der Wahrnehmung berechtigter Interessen der Beteiligten,
            Art. 6 Abs. 1 Buchstabe f DSGVO. Zu Zwecken der
            Öffentlichkeitsarbeit werden die Fotos und/oder Videos auf der
            Homepage des Tiroler Bildungsforums eingestellt sowie für die
            Facebook-Seite der Erwachsenenschule verwendet. Weiters werden sie
            zwecks Öffentlichkeitsarbeit an Printmedien weitergegeben. Fotos-
            und/oder Videos, welche für die Zwecke der Öffentlichkeitsarbeit des
            VEREINS gemacht werden, werden vorbehaltlich eines Widerrufs der
            Einwilligung des/der Betroffenen auf unbestimmte Zeit zweckgebunden
            gespeichert. Die Einwilligung zur Verarbeitung der Fotos und/oder
            Videos kann jederzeit für die Zukunft widerrufen werden. Die
            Rechtmäßigkeit der aufgrund der Einwilligung bis zum Widerruf
            erfolgten Datenverarbeitung wird durch diesen nicht berührt.
          </Paragraph>
        </div>
      </EntityGroup>
    </Page>
  );
}

export default ImprintView;
