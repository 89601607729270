import { css } from "styled-components";

const ActionBase = css`
  position: relative;

  display: inline-flex;
  flex-direction: row;
  align-items: center;
  justify-content: center;
  flex-wrap: nowrap;

  padding: 0;
  outline: none;
  overflow: visible;

  font-family: inherit;
  font-size: 0.9rem;
  line-height: inherit;
  font-weight: bold;
  text-transform: uppercase;
  text-decoration: none;

  background-color: transparent;
  color: inherit;

  transition:all 0.2s ease-in-out;

  border: 0;

  user-select: none;
  cursor: pointer;

  :hover {
    text-decoration: none;
    color: black;
  }

  :focus {
    outline: none;
  }

  > svg {
    margin-left: 0.2rem;
  }
`;

export default ActionBase;
