import React from "react";
import styled from "styled-components";
import TbfLogoImg from "../../assets/tbf-logo.png";
import Typo from "../../ui/Typo";
import LinkReset from "../../ui/primitives/LinkReset";

const Container = styled.div`
  margin-top: 2rem;
  padding: 1rem 0 0 0;
  border-top: 1px solid #d6d6d6;
  width: 100%;
`;

const TbfLogo = styled.a`
  ${LinkReset}
`;

const TbfHeaderInfo = () => (
  <Container>
    <TbfLogo href="https://tiroler-bildungsforum.at/" alt="Tiroler Bildungsforum" target="_blank">
      <Typo size="0.9rem" margin="0 0 0.5rem 0" weight="bold" >Ein Service des</Typo>
      <img src={TbfLogoImg} style={{ width: "100px" }} />
    </TbfLogo>
  </Container>
);

export default TbfHeaderInfo;
