import React from "react";
import { ChevronRight } from "react-feather";

import Page from "../../ui/web/Page";
import Typo, {
  Paragraph,
  Header,
  Header2,
  List,
  ListItem,
} from "../../ui/Typo";

import View from "../../ui/View";
import Chip from "../../ui/Chip";
import Action from "../../ui/Action";

import ListView from "../../ui/ListView";
import ListViewItem from "../../ui/ListViewItem";

import PetraImg from "../../assets/petra-obojes.jpg";
import MatthiasImg from "../../assets/matthias-karadar.jpg";
import ChristophImg from "../../assets/christoph-klocker.jpg";

const headerText = "Servicestelle Gemeinschaftsgärten";

function ServicePoint() {
  return (
    <Page header={{ text: headerText, heroheader: false }}>
      <View withAside>
        <div>
          <Paragraph>
            Die „Servicestelle Gemeinschaftsgärten Tirol“ wurde 2012 als Bereich
            des Tiroler Bildungsforums - Verein für Kultur und Bildung
            gegründet.
            <div style={{marginTop:"0.2rem"}}>
              <Action
                href="https://www.tiroler-bildungsforum.at/"
                target="_blank"
                as="a"
              >
                Tiroler Bildungsforum <ChevronRight size="16px" />
              </Action>
            </div>
          </Paragraph>

          <Header>Unsere Motivation</Header>
          <Paragraph>
            Die Erfahrungen, die wir ab 2008 beim Aufbau und der mehrjährigen
            Koordination des Interkulturellen Gemeinschaftsgarten Innsbruck –
            Wilten machen durften, haben gezeigt, was auch in anderen Teilen der
            Welt bereits erfahren wurde: Gemeinschaftsgärten tragen auf
            vielfältige Weise Früchte – vom eigenen Gemüse und den süßen Beeren
            über den sozialen Zusammenhalt bis hin zu vielfältigem
            Erfahrungslernen für Einzelne, die Gruppe, die Gemeinde, den
            Stadtteil.
          </Paragraph>
          <Paragraph>
            Beratung, Bildungsangebote und Vernetzung sollen dazu beitragen,
            dass viele Gemeinschaftsgärten in Tirol sprießen!
          </Paragraph>

          <Header>Unser Netzwerk</Header>
          <Paragraph>
            Wir sind mit über 30 gemeinschaftlichen Garteninitiativen in Tirol
            in Kontakt und tauschen uns mit Engagierten in den angrenzenden
            Bundesländern, Südtirol und Bayern aus. Österreichweit arbeiten wir
            mit dem Verein Gartenpolylog zusammen, der seit 2007 Netzwerk- und
            Bildungsarbeit zum Thema Gemeinschaftsgärten leistet.
            <div style={{marginTop:"0.2rem"}}>
              <Action
                href="https://www.gartenpolylog.org/"
                target="_blank"
                as="a"
              >
                Gartenpolylog <ChevronRight size="16px" />
              </Action>
            </div>
          </Paragraph>

          <Header>Unsere Arbeitsbereiche</Header>
          <List>
            <ListItem>
              Wir unterstützen beim Gartenstart: Beratung, Infoveranstaltungen,
              Begleitung von Gartengruppen in der Startphase, Musterformulare
            </ListItem>

            <ListItem>
              Wir bieten Möglichkeiten der Vernetzung und Weiterentwicklung von
              bestehenden Gärten: Vernetzungstreffen, themenspezifische
              Veranstaltungen, Newsletter
            </ListItem>
            <ListItem>
              Wir verstehen uns als allgemeine Anlaufstelle für interessierte
              Personen, Vereine, Gemeinden und Institutionen und verleihen
              Medien (Bücher, DVDs).
            </ListItem>

            <ListItem>
              Wir betreiben die Website gemeinsam-garteln.tirol und setzen uns
              für Bewusstseinsbildung über die Potentiale von
              Gemeinschaftsgärten in der Öffentlichkeit ein.
            </ListItem>

            <Paragraph>
              Diese Serviceleistungen bieten wir weitgehend kostenlos an.
            </Paragraph>
            <Paragraph noBorder>
              Zudem beteiligen wir uns an Programmen und Aktionen und führen
              Projekte - auch grenzüberschreitend - durch.
            </Paragraph>
            <div style={{marginTop:"0.2rem"}}>
              <Action
                href="https://blog.gemeinsam-garteln.tirol/tag/projekte/"
                target="_blank"
                as="a"
              >
                Unsere Projekte <ChevronRight size="16px" />
              </Action>
            </div>
          </List>
          <Header>Kontakt & Öffnungszeiten</Header>
          <Paragraph>
            Tiroler Bildungsforum (TBF)
            <br />
            Sillgasse 8/2, 6020 Innsbruck <br />
            T +43 (0) 512 581465 <br />
          </Paragraph>
          <Paragraph>
            Öffnungszeiten:
            <br />
            Tiroler Bildungsforum, Büro: Montag-Freitag 8-12.00 Uhr
          </Paragraph>
          <Paragraph>
            Erreichbarkeit Servicestelle Gemeinschaftsgärten: <br />
            Nach Terminvereinbarung!
            <br />T +43 (0) 664 88467006
          </Paragraph>
        </div>
        <div>
          <View.SecondaryContent>
            <View.Section>
              <Header2>
                Werde Teil des Tiroler Netzwerks der Gemeinschaftsgärten!
              </Header2>
              <List>
                <ListItem>
                  Trage deinen Gemeinschaftsgarten auf der Homepage ein
                </ListItem>
                <ListItem>
                  Gib Veranstaltungen Eures Gemeinschaftsgartens über die
                  Homepage bekannt
                </ListItem>
                <ListItem>Registriere dich für den Newsletter</ListItem>
                <ListItem>
                  Teile deine Erfahrungen im Blog und gib dein Wissen weiter
                </ListItem>
                <ListItem>
                  Komm zu Vernetzungstreffen und anderen Veranstaltungen
                </ListItem>
                <ListItem>
                  oder nimm einfach Kontakt mit der Leiterin der Servicestelle
                  auf
                </ListItem>
              </List>
            </View.Section>
            <View.Divider />
            <View.Section>
              <Header2>Werde Mitglied des Tiroler Bildungsforums</Header2>
              <Paragraph>
                Gemeinschaftsgärten können Mitglied beim Tiroler Bildungsforum
                werden (Jahresbeitrag für Gruppen € 80.-) und damit weitere
                Angebote nutzen:
              </Paragraph>
              <List>
                <ListItem>Vereinshaftpflichtversicherung</ListItem>
                <ListItem>
                  Kostenlose Inanspruchnahme oder Vergünstigungen für jeweils
                  drei Mitglieder bei Bildungsveranstaltungen des Tiroler
                  Bildungsforums
                </ListItem>
                <ListItem>
                  10 Freikarten für die Blühenden Träume, Tiroler Gartentage
                </ListItem>
              </List>
              <Action
                href="https://tiroler-bildungsforum.at/"
                target="_blank"
                as="a"
              >
                Jetzt Mitglied werden <ChevronRight size="16px" />
              </Action>
            </View.Section>
          </View.SecondaryContent>
        </div>
      </View>
      <div>
        <Header>Team</Header>
        <ListView>
          <ListView.Items>
            <ListViewItem>
              <ListViewItem.Header mediaUrl={PetraImg} position="top center" height="400px"/>
              <ListViewItem.Content>
                <ListViewItem.Title>
                  <Typo family="Roboto Slab">
                    Mag.a Petra Obojes-Signitzer, DSA
                  </Typo>
                </ListViewItem.Title>
                <ListViewItem.Text full>
                  Geboren 1980 in Tirol. Als Sozialarbeiterin und Kultur- und
                  Sozialanthropologin führte Petra’s Weg über mehrere
                  Auslandsaufenthalte mit Tätigkeiten in kleinbäuerlichen
                  Strukturen und der Beschäftigung mit der Subsistenzperspektive
                  zur Permakultur – und wieder zurück nach Tirol. Seither ist
                  die Verbindung von Mensch und Garten ihre gelebte Praxis,
                  privat und beruflich.
                </ListViewItem.Text>
                <ListViewItem.PropertyList>
                  <Chip highlighted>Leitung</Chip>
                </ListViewItem.PropertyList>
              </ListViewItem.Content>
            </ListViewItem>
            <ListViewItem>
              <ListViewItem.Header mediaUrl={MatthiasImg} position="top center" height="400px"/>
              <ListViewItem.Content>
                <ListViewItem.Title>
                  <Typo family="Roboto Slab">Matthias Karadar, MSc.</Typo>
                </ListViewItem.Title>
                <ListViewItem.Text full>
                  Geboren 1988 in Südtirol. Bereits als Kind hatte Matthias je
                  ein Gemüsebeet bei beiden Großeltern. Während des Studiums der
                  Biologie und Botanik wurde die Liebe zum Gartln
                  wiederentdeckt. Und im Anschluss darauf zum Beruf gemacht.
                  Seither verbreitet er durch die Initiative 'Natur im Garten'
                  die Idee des naturnahen Gärtnerns in Tirol.
                </ListViewItem.Text>
                <ListViewItem.PropertyList>
                  <Chip highlighted>
                    fachliche Mitarbeit,
                    <br /> Projektleiter Natur im Garten Tirol
                  </Chip>
                </ListViewItem.PropertyList>
              </ListViewItem.Content>
            </ListViewItem>
          </ListView.Items>
        </ListView>
      </div>
    </Page>
  );
}

export default ServicePoint;
