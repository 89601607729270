import styled from "styled-components";

const Bar = styled.div`
  display: grid;
  grid-template-columns: auto auto;
  width: 100%;
`;

const Left = styled.div`
  display: flex;
  flex-direction: row;
  align-items: center;
  justify-content: flex-start;
  width: 100%;

  > * {
    margin-right: 1rem;
  }
`;

const Right = styled.div`
  display: flex;
  flex-direction: row;
  align-items: center;
  justify-content: flex-end;
  width: 100%;
`;

Bar.Left = Left;
Bar.Right = Right;

export default Bar;
