import styled, { css } from "styled-components";
import ActionList from "./ActionList";
import LinkReset from "./primitives/LinkReset";

const Header = styled.div`
  height: ${props => (props.height || "300px")};
  width: 100%;
  background-color: ${props => props.theme.color.contentBackground};

  ${props =>
    props.mediaUrl &&
    css`
      background-position: ${props => (props.position || "center center")};
      background-repeat: no-repeat;
      background-size: cover;
      background-image: url(${props.mediaUrl});
    `}
`;

const Overlay = styled.div`
  position: absolute;
  top: 20px;
  right: 20px;
  height: auto;
`;

const Content = styled.div`
  margin: 1rem;

  @media (min-width: 1000px) {
    margin: 1.4rem;
  }
`;

const Title = styled.div`
  font-size: ${props => (props.small ? "1.1rem" : "1.4rem")};
  font-weight: bold;
  margin-bottom: 0.5rem;
  height: ${props => (props.small ? "50px" : "70px")};
  overflow: hidden;
`;

const Text = styled.div`
  font-size: 0.9rem;
  margin-bottom: 0.8rem;
  height: ${props => (props.full ? "auto" : "5.5rem")};
  overflow: hidden;
  word-break: break-word;
`;

const PropertyList = styled.div`
  word-break:break-all;
  font-size: 0.8rem;
  color: #7b7b7b;
  margin-bottom: 0.4rem;
  > * {
    margin-bottom: 0.4rem;
  }
`;

const PropertyGroup = styled.div`
  display: flex;
  flex-wrap: wrap;
  flex-direction: row;
  align-items: center;
  > * {
    margin-right: 0.3rem;
  }
`;

const Actions = styled(ActionList)`
  margin-top: 1rem;
  padding: 1rem 0 0 0;
  justify-content: flex-start;
  > * {
    margin-right: 0.8rem;
  }
`;

const ListViewItem = styled.div`
  height: 100%;
  position: relative;
  border: 1px solid #d6d6d6;
  background-color: white;

  ${props =>
    props.link &&
    css`
      display: block;
      ${LinkReset}
      :hover {
        border-color: black;
        transition: all 0.4s ease-out;
      }
    `};
`;

ListViewItem.Header = Header;
ListViewItem.Overlay = Overlay;
ListViewItem.Content = Content;
ListViewItem.Title = Title;
ListViewItem.Text = Text;
ListViewItem.PropertyList = PropertyList;
ListViewItem.PropertyGroup = PropertyGroup;
ListViewItem.Actions = Actions;

export default ListViewItem;
