import React, { useState } from "react";
import Subscribe from "unstated-subscribe-hoc";

import HubStateContainer from "../../HubStateContainer";

import Page from "../../ui/web/Page";
import { Form } from "../../ui/Form";
import { InputGroup } from "../../ui/Input";
import { Label, TextInput } from "../../ui/Form";
import Action from "../../ui/Action";
import Typo from "../../ui/Typo";
import ActionList from "../../ui/ActionList";
import EntityGroup from "../../ui/EntityGroup";

const staticContents = {
  title: "Registrieren",
  description:
    "Als registrierter Benutzer auf Gemeinschaftsgärten.tirol bekommst du Updates zur Seite, kannst Termine veröffentlichen und deinen eigenen Gemeinschaftsgarten eintragen.",
  form: {
    username: "Benutzername",
    email: "E-Mail",
    password: "Passwort",
    submit_successful: "Registrierung erfolgreich!",
    submit_failed:
      "Registrierung fehlgeschlagen. Benutzername/E-Mail bereits vorhanden.",
    submit_button: "Registrieren"
  }
};

const InitialFormData = { username: "", email: "", password: "" };

function SignupForm({ context }) {
  const [formData, setFormData] = useState(InitialFormData);
  const [submissionSuccessful, setSubmissionSuccessful] = useState(false);
  const [submissionError, setSubmissionError] = useState(false);

  const formDataChanged = (field, value) => {
    let newState = { ...formData };
    newState[field] = value;
    setFormData(newState);
  };

  const handleInputChange = event => {
    formDataChanged(event.target.name, event.target.value);
  };

  const isFormDataValid = () => {
    if (formData.username && formData.email && formData.password) {
      return true;
    }
  };

  const handleSubmit = event => {
    context
      .signUp(formData.username, formData.email, formData.password)
      .then(auth => {
        setSubmissionSuccessful(true);
      })
      .catch(e => {
        setSubmissionError(true);
        console.log(e.message);
      });

    event.preventDefault();
  };

  const renderResponse = (
    <div>
      <Typo size="1.2rem" weight="700">
        {staticContents.form.submit_successful}
      </Typo>
    </div>
  );

  const renderSubmissionError = (
    <div>
      <Typo color="red" margin="1rem 0;" size="0.8rem">
        {staticContents.form.submit_failed}
      </Typo>
    </div>
  );

  const renderFormInput = (
    <div>
      <InputGroup columns="1fr">
        <div>
          <Label htmlFor="username">{staticContents.form.username}</Label>
          <TextInput
            name="username"
            type="text"
            value={formData.username}
            onChange={handleInputChange}
            required
          />
        </div>
        <div>
          <Label htmlFor="email">{staticContents.form.email}</Label>
          <TextInput
            name="email"
            type="email"
            value={formData.email}
            onChange={handleInputChange}
            required
          />
        </div>
        <div>
          <Label htmlFor="password">{staticContents.form.password}</Label>
          <TextInput
            name="password"
            type="password"
            value={formData.password}
            onChange={handleInputChange}
            required
          />
        </div>
      </InputGroup>
      {submissionError && renderSubmissionError}
      <ActionList noBorder>
        <Action
          buttonStyle
          type="submit"
          value="Submit"
          disabled={!isFormDataValid()}
        >
          {staticContents.form.submit_button}
        </Action>
      </ActionList>
    </div>
  );

  const renderForm = (
    <Form onSubmit={handleSubmit}>
      {!submissionSuccessful ? renderFormInput : renderResponse}
    </Form>
  );

  return (
    <Page>
      <EntityGroup>
        <div>
          <Typo size="2rem">{staticContents.title}</Typo>
          <Typo margin="2rem 0 0 0" size="1.2rem">
            {staticContents.description}
          </Typo>
        </div>
        <div>{renderForm}</div>
      </EntityGroup>
    </Page>
  );
}

export default Subscribe(SignupForm, { context: HubStateContainer });
