import styled from "styled-components";
import { ViewBreakpoint } from "./Theme";

const ratio = {
  "1-1": "minmax(0, 1fr) minmax(0, 1fr)",
  "3-1": "minmax(0, 3fr) minmax(0, 1fr)",
  "1-2": "1fr 2fr",
  "1-3": "1fr 3fr"
};

const EntityGroup = styled.div`
  display: grid;
  grid-gap: 1rem;
  @media ${ViewBreakpoint["m"]} {
    grid-template-columns: ${props =>
      props.ratio ? ratio[props.ratio] : ratio["1-1"]};
  }
`;

export default EntityGroup;
