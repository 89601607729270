import React, { useState } from "react";
import Subscribe from "unstated-subscribe-hoc";
import { NavLink } from "react-router-dom";

import HubStateContainer from "../../HubStateContainer";

import Typo from "../../ui/Typo";
import Page from "../../ui/web/Page";
import { Form } from "../../ui/Form";
import { InputGroup } from "../../ui/Input";
import { Label, TextInput } from "../../ui/Form";
import Action from "../../ui/Action";
import ActionList from "../../ui/ActionList";
import EntityGroup from "../../ui/EntityGroup";
import ActionLinkText from "../../ui/ActionLinkText";

const staticContents = {
  title: "Anmelden",
  description_signup:
    "Noch kein Konto? Einfach Registrieren um Veranstaltungen und Gemeinschaftsgärten einzutragen.",
  signup_link: "Jetzt Registrieren",
  description_passwordreset:
    "ACHTUNG! Wir haben am 25.09.2019 die Seite auf ein neues System übertragen. Die Passwörter wurden dabei zurückgestellt. Wenn du dich seither noch nicht eingeloggt oder dein Passwort zurück gesetzt hast, musst du einmalig ",
  passwordreset_link: "hier ein neues Passwort beantragen.",
  form: {
    identifier: "E-Mail/Benutzername",
    password: "Passwort",
    passwordreset: "Passwort zurücksetzen",
    submit_failed:
      "Anmeldung fehlgeschlagen. Bitte überprüfe E-Mail/Benutzername und Passwort.",
    submit_button: "Anmelden"
  }
};

const InitialFormData = { identifier: "", password: "" };

function LoginForm({ context, ...props }) {
  const [formData, setFormData] = useState(InitialFormData);
  const [submissionError, setSubmissionError] = useState(false);

  const formDataChanged = (field, value) => {
    let newState = { ...formData };
    newState[field] = value;
    setFormData(newState);
  };

  const handleInputChange = event => {
    formDataChanged(event.target.name, event.target.value);
  };

  const handleSubmit = event => {
    context
      .login(formData.identifier, formData.password)
      .then(auth => {
        props.history.push("/");
      })
      .catch(e => {
        setSubmissionError(true);
        console.log(e.message);
      });

    event.preventDefault();
  };

  const renderSubmissionError = (
    <div>
      <Typo color="red" margin="1rem 0;" size="0.8rem">
        {staticContents.form.submit_failed}
      </Typo>
    </div>
  );

  const renderFormInput = (
    <div>
      <InputGroup columns="1fr">
        <div>
          <Label htmlFor="identifier">{staticContents.form.identifier}</Label>
          <TextInput
            name="identifier"
            type="text"
            value={formData.identifier}
            onChange={handleInputChange}
            required
          />
        </div>
        <div>
          <Label htmlFor="password">{staticContents.form.password}</Label>
          <TextInput
            name="password"
            type="password"
            value={formData.password}
            onChange={handleInputChange}
            required
          />
        </div>
        <Typo size="0.8rem">
          <ActionLinkText to="/pwdreset" as={NavLink}>
            {staticContents.form.passwordreset}
          </ActionLinkText>
        </Typo>
      </InputGroup>
      {submissionError && renderSubmissionError}
      <ActionList noBorder>
        <Action buttonStyle type="submit" value="Submit">
          {staticContents.form.submit_button}
        </Action>
      </ActionList>
    </div>
  );

  const renderForm = <Form onSubmit={handleSubmit}>{renderFormInput}</Form>;

  return (
    <Page>
      <EntityGroup>
        <div>
          <Typo size="2rem">{staticContents.title}</Typo>
          <Typo margin="2rem 0 0 0" size="1.2rem">
            {staticContents.description_signup}{" "}
            <ActionLinkText to="/signup" as={NavLink}>
              {staticContents.signup_link}
            </ActionLinkText>
          </Typo>
          <Typo margin="1rem 0 0 0" size="0.9rem">
            {staticContents.description_passwordreset}
            <ActionLinkText to="/pwdreset" as={NavLink}>
              {staticContents.passwordreset_link}
            </ActionLinkText>
          </Typo>
        </div>
        <div>{renderForm}</div>
      </EntityGroup>
    </Page>
  );
}

export default Subscribe(LoginForm, { context: HubStateContainer });
