import { css } from "styled-components";

const LinkReset = css`
  color: inherit;
  text-decoration: none;
  cursor: pointer;

  outline: none;

  :hover {
    color: inherit;
    text-decoration: none;
  }

  :focus {
    outline: none;
  }
`;

export default LinkReset;
