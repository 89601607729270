import { Container } from "unstated";
import StrapiDateProvider from "./data/StrapiDateProvider";

class HubStateContainer extends Container {
  constructor() {
    super();
    this.dataProvider = new StrapiDateProvider(
      "https://api.gemeinsam-garteln.tirol/",
      "https://gemeinsam-garteln.tirol/pwdresetconfirm"
    );

    this.state = {
      user: { isloggedin: false, userData: null },
      notifications: { items: [], selectedItem: {} },
      events: { items: [], selectedItem: {} },
      gardens: { items: [], selectedItem: {} },
      articles: { items: [], selectedItem: {} }
    };
  }

  //AUTH

  async login(identifier, password) {
    const auth = await this.dataProvider.login(identifier, password);
    if (auth) {
      console.log("User logged in:", auth);
      this.setState(state => {
        return { user: { isloggedin: true, userData: auth.user } };
      });
    }
  }

  isAuthorized(userId) {
    if (this.state.user.userData.role.name === "Administrator") {
      return true;
    } else if (this.state.user.userData._id === userId) {
      return true;
    }
  }

  logout() {
    this.dataProvider.logout();
    this.setState(state => {
      return { user: { isloggedin: false, userData: null } };
    });
  }

  async signUp(username, email, password) {
    const auth = await this.dataProvider.signUp(username, email, password);
    if (auth) {
      this.setState(state => {
        return { user: { isloggedin: true, userData: auth.user } };
      });
    }
  }

  async forgotPassword(email) {
    const response = await this.dataProvider.forgotPassword(email);
    return response;
  }

  async resetPassword(code, password, passwordConfirmation) {
    const response = await this.dataProvider.resetPassword(
      code,
      password,
      passwordConfirmation
    );
    return response;
  }

  //AUTH-END

  async syncList(type, params) {
    const syncedItems = await this.dataProvider.getList(type, params);
    this.setState(state => {
      return {
        [type]: {
          items: syncedItems,
          selectedItem: state[type].selectedItem
        }
      };
    });
  }

  async sync(type, id) {
    const syncedItem = await this.dataProvider.get(type, id);
    console.log("Content synced (Backend):", syncedItem);
    this.setState(state => {
      return {
        [type]: {
          items: state[type].items,
          selectedItem: syncedItem
        }
      };
    });
  }

  unsync(type) {
    this.setState(state => {
      return {
        [type]: {
          items: state[type].items,
          selectedItem: {}
        }
      };
    });
  }

  async add(type, data) {
    const userId = this.state.user.userData._id;
    const sendData = { user: userId, ...data };
    try {
      const addedItem = await this.dataProvider.add(type, sendData);
      this.syncList(type);
      console.log("Content added", addedItem);
      return addedItem;
    } catch (error) {
      console.error(error);
    }
  }

  async update(type, id, data) {
    try {
      const updatedItem = await this.dataProvider.update(type, id, data);
      this.syncList(type);
      console.log("Content updated", updatedItem);
      return updatedItem;
    } catch (error) {
      console.error(error);
    }
  }

  async remove(type, id) {
    try {
      const removedItem = await this.dataProvider.remove(type, id);
      this.syncList(type);
      console.log("Content removed", removedItem);
      return removedItem;
    } catch (error) {
      console.error(error);
    }
  }

  async upload(data, config) {
    try {
      const uploadedFiles = await this.dataProvider.upload(data, config);
      this.syncList("gardens");
      console.log("File uploaded", uploadedFiles);
      return uploadedFiles;
    } catch (error) {
      console.error(error);
    }
  }
}

export default HubStateContainer;
