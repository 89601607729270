import Strapi from "strapi-sdk-javascript/build/main";

class StrapiDataProvider {
  constructor(baseURL, pwdConfirmURL) {
    this.strapi = new Strapi(baseURL);
    this.pwdConfirmURL = pwdConfirmURL;
  }

  async login(identifier, password) {
    const auth = await this.strapi.login(identifier, password);
    return auth;
  }

  logout() {
    this.strapi.clearToken();
  }

  async signUp(username, email, password) {
    const user = await this.strapi.register(username, email, password);
    return user;
  }

  async forgotPassword(email) {
    const response = await this.strapi.forgotPassword(email, this.pwdConfirmURL);
    return response;
  }

  async resetPassword(code, password, passwordConfirmation) {
    const response = await this.strapi.resetPassword(
      code,
      password,
      passwordConfirmation
    );

    return response;
  }

  async getList(type, params) {
    const items = await this.strapi.getEntries(type, params);
    return items;
  }

  async get(type, id) {
    const item = await this.strapi.getEntry(type, id);
    return item;
  }

  async add(type, data) {
    const addedItem = await this.strapi.createEntry(type, data);
    return addedItem;
  }

  async update(type, id, data) {
    const updatedItem = await this.strapi.updateEntry(type, id, data);
    return updatedItem;
  }

  async remove(type, id) {
    const removedItem = await this.strapi.deleteEntry(type, id);
    return removedItem;
  }

  async upload(data, config) {
    const files = await this.strapi.upload(data, config);
    return files;
  }
}

export default StrapiDataProvider;
