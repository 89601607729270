import React, { useState, useRef } from "react";
import { Plus, XCircle, Trash2 } from "react-feather";
import getThumb from "../../data/CloudinaryProvider";

import { InputGroup } from "../../ui/Input";
import Image from "../../ui/Image";
import Action from "../../ui/Action";
import { Form, TextInput, TextArea, Select, Label } from "../../ui/Form";

import { FieldCategories } from "./EventType";

function EventForm(props) {
  const [formData, setFormData] = useState(props.typeData);
  const headerImageInputData = useRef(null);

  const formDataChanged = (field, value) => {
    let newState = { ...formData };
    newState[field] = value;
    setFormData(newState);
  };

  const handleToggleChange = event => {
    formDataChanged(event.target.name, !formData[event.target.name]);
  };

  const handleInputChange = event => {
    formDataChanged(event.target.name, event.target.value);
  };

  const handleLinkChange = (changedIndex, field) => event => {
    const newLinks = formData.links.map((link, linkIndex) => {
      if (changedIndex !== linkIndex) return link;
      return { ...link, [field]: event.target.value };
    });
    formDataChanged("links", newLinks);
  };

  const handleLinkAdd = event => {
    event.preventDefault();
    formDataChanged(
      "links",
      formData.links.concat([{ description: "", url: "" }])
    );
  };

  const handleLinkRemove = index => event => {
    event.preventDefault();
    formDataChanged(
      "links",
      formData.links.filter((s, sidx) => index !== sidx)
    );
  };

  const handleHeaderImageRemoved = event => {
    event.preventDefault();
    formDataChanged("headerImage", null);
  };

  const handleSubmit = event => {
    event.preventDefault();

    let formFiles = { headerImage: null };

    if (headerImageInputData.current) {
      if (headerImageInputData.current.files[0]) {
        let fileFormData = new FormData();
        fileFormData.append("files", headerImageInputData.current.files[0]);
        formFiles.headerImage = fileFormData;
      }
    }

    props.onSubmitted(formData, formFiles);
  };

  const handleCancelled = () => {
    props.onCancelled();
  };

  return (
    <Form onSubmit={handleSubmit}>
      <Form.Header>Neue Veranstaltung</Form.Header>
      <Form.Contents>
        <Form.PrimaryContent>
          <InputGroup columns="2fr 1fr">
            <div>
              <Label htmlFor="name">Titel *</Label>
              <TextInput
                type="text"
                name="name"
                value={formData.name}
                onChange={handleInputChange}
                required
              />
            </div>

            <div>
              <Label htmlFor="category">Kategorie *</Label>
              <Select
                fullWidth
                name="category"
                value={formData.category}
                onChange={handleInputChange}
                required
              >
                {FieldCategories.map(field => (
                  <option key={field} value={field}>
                    {field}
                  </option>
                ))}
              </Select>
            </div>
          </InputGroup>

          <InputGroup columns="1fr">
            <div>
              <Label htmlFor="description">Beschreibung *</Label>
              <TextArea
                name="description"
                value={formData.description}
                onChange={handleInputChange}
                rows="20"
                required
              />
            </div>
          </InputGroup>

          {formData.links.map((link, index) => (
            <InputGroup columns="1fr 2fr auto" key={index}>
              <div>
                <Label htmlFor="linkDescription">Beschreibung</Label>
                <TextInput
                  name="linkDescription"
                  value={link.description}
                  onChange={handleLinkChange(index, "description")}
                />
              </div>
              <div>
                <Label htmlFor="linkUrl">Url</Label>
                <TextInput
                  name="linkUrl"
                  value={link.url}
                  onChange={handleLinkChange(index, "url")}
                />
              </div>
              <Action onClick={handleLinkRemove(index)}>
                <Trash2 size="16px" />
              </Action>
            </InputGroup>
          ))}

          <InputGroup columns="1fr" items="start">
            <Action onClick={handleLinkAdd}>
              Link Hinzufügen
              <Plus size="16px" />
            </Action>
          </InputGroup>

          <InputGroup columns="1fr 1fr">
            <div>
              <Label htmlFor="costs">Kosten</Label>
              <TextInput
                type="text"
                name="costs"
                value={formData.costs}
                onChange={handleInputChange}
              />
            </div>
            <div>
              <Label htmlFor="audience">Zielgruppe</Label>
              <TextInput
                type="text"
                name="audience"
                value={formData.audience}
                onChange={handleInputChange}
              />
            </div>
          </InputGroup>

          <InputGroup columns="1fr">
            <div>
              <Label htmlFor="registration">Anmeldung</Label>
              <TextInput
                type="text"
                name="registration"
                value={formData.registration}
                onChange={handleInputChange}
              />
            </div>
          </InputGroup>

          <InputGroup columns="1fr">
            <div>
              <Label htmlFor="contact">Kontakt</Label>
              <TextInput
                type="text"
                name="contact"
                value={formData.contact}
                onChange={handleInputChange}
              />
            </div>
          </InputGroup>
        </Form.PrimaryContent>
        <Form.SecondaryContent>
          <InputGroup columns="1fr">
            <Label htmlFor="headerImage">Titelbild</Label>
            {formData.headerImage ? (
              <div>
                <div>
                  <Image src={getThumb(formData.headerImage.url, 900)} />
                </div>
                <div>
                  <Action onClick={handleHeaderImageRemoved}>
                    <Trash2 size="16px" />
                  </Action>
                </div>
              </div>
            ) : (
              <input
                name="headerImage"
                type="file"
                ref={headerImageInputData}
              />
            )}
          </InputGroup>

          <InputGroup columns="1fr">
            <div>
              <Label htmlFor="tbfHosted">
                <input
                  type="checkbox"
                  name="tbfHosted"
                  checked={formData.tbfHosted}
                  onChange={handleToggleChange}
                />
                TBF Veranstaltung
              </Label>
            </div>
          </InputGroup>

          <InputGroup columns="1fr">
            <div>
              <Label htmlFor="dateStart">Beginn (tt.mm.jjjj) *</Label>
              <TextInput
                type="date"
                name="dateStart"
                value={formData.dateStart}
                onChange={handleInputChange}
                required
                pattern="[0-9]{2}.[0-9]{2}.[0-9]{4}"
              />
            </div>
            <div>
              <Label htmlFor="dateEnd">Ende (tt.mm.jjjj)</Label>
              <TextInput
                type="date"
                name="dateEnd"
                value={formData.dateEnd}
                onChange={handleInputChange}
                pattern="[0-9]{2}.[0-9]{2}.[0-9]{4}"
              />
            </div>
            <div>
              <Label htmlFor="schedule">Zeitplan *</Label>
              <TextArea
                name="schedule"
                value={formData.schedule}
                onChange={handleInputChange}
                rows="7"
                required
              />
            </div>
          </InputGroup>

          <InputGroup columns="1fr">
            <div>
              <Label htmlFor="locationCity">Stadt *</Label>
              <TextInput
                type="text"
                name="locationCity"
                value={formData.locationCity}
                onChange={handleInputChange}
                required
              />
            </div>
            <div>
              <Label htmlFor="locationPlace">
                Ort (Strasse/ Räumlichkeit) *
              </Label>
              <TextInput
                type="text"
                name="locationPlace"
                value={formData.locationPlace}
                onChange={handleInputChange}
                required
              />
            </div>
          </InputGroup>
        </Form.SecondaryContent>
      </Form.Contents>
      <Form.Actions>
        <Action onClick={handleCancelled}>
          Abbrechen
          <XCircle size="16px" />
        </Action>
        <Action buttonStyle type="submit" value="Submit">
          Abschicken
          <Plus size="16px" />
        </Action>
      </Form.Actions>
    </Form>
  );
}

export default EventForm;
