import React, { useState, useEffect } from "react";
import Subscribe from "unstated-subscribe-hoc";
import getThumb from "../../data/CloudinaryProvider";
import GhostContentAPI from "@tryghost/content-api";

import {MapPin, Tag, Trash2, ChevronRight, Plus } from "react-feather";
import { NavLink } from "react-router-dom";

import HubStateContainer from "../../HubStateContainer";

import headerImage from "../../assets/header2.jpg";

import Page from "../../ui/web/Page";
import Typo from "../../ui/Typo";
import TypeSection from "../../ui/TypeSection";
import ListView from "../../ui/ListView";
import ListViewItem from "../../ui/ListViewItem";
import Chip from "../../ui/Chip";
import TbfOverlay from "../../ui/custom/TbfOverlay";
import DateOverlay from "../../ui/DateOverlay";
import NotificationForm from "../../views/notification/NotificationForm";
import Action from "../../ui/Action";
import ActionList from "../../ui/ActionList";

import TbfHeaderInfo from "./TbfHeaderInfo";

const ghostBlog = new GhostContentAPI({
  url: "https://blog.gemeinsam-garteln.tirol",
  key: "91ed9ac233b50f552764e246c1",
  version: "v2",
});

const staticContents = {
  title: "Netzwerk der Gemeinschaftsgärten in Tirol",
};

function HomeView({ context, ...props }) {
  const today = new Date().toISOString().substring(0, 10);

  const [notificationFormVisible, setNotificationFormVisible] = useState(false);
  const [articles, setArticles] = useState([]);

  useEffect(() => {
    fetchBlogPosts();
  }, []);

  const fetchBlogPosts = async () => {
    const result = await ghostBlog.posts.browse({
      limit: 3,
      include: "tags",
    });
    setArticles(result);
  };

  useEffect(() => {
    context.syncList("notifications");
    context.syncList("events", {
      _limit: 3,
      _sort: "dateStart:asc",
      dateStart_gte: today,
    });
  }, [context, today]);

  const handleNotificationSubmit = (formData) => {
    context
      .add("notifications", formData)
      .then(() => toggleNotificationFormVisiblity());
  };

  const toggleNotificationFormVisiblity = () => {
    setNotificationFormVisible(!notificationFormVisible);
  };

  const handleNewNotificationClick = () => {
    if (context.state.user.isloggedin) {
      toggleNotificationFormVisiblity();
    } else {
      props.history.push("/login");
    }
  };

  return (
    <Page
      header={{
        text: staticContents.title,
        heroheader: true,
        overlay: true,
        bgimg: headerImage,
      }}
      customSubheader={<TbfHeaderInfo/>}
    >
      <TypeSection>
        <div>
          <Typo family="Roboto Slab" size="1.8rem" weight="700">
            Aktuelles
          </Typo>
        </div>
        <div>
          <ListView>
            {context.state.notifications.items.length > 0 && (
              <>
                <ListView.Items>
                  {context.state.notifications.items.map((notification) => (
                    <ListViewItem key={notification.id}>
                      <ListViewItem.Content>
                        <ListViewItem.Title small>
                          <Typo family="Roboto Slab">
                            {notification.category}
                          </Typo>
                        </ListViewItem.Title>
                        <ListViewItem.Text>
                          {notification.text}
                        </ListViewItem.Text>
                        <ListViewItem.Text>
                        {notification.contact && (
                            <div>{notification.contact}</div>
                          )}
                        </ListViewItem.Text>
                        {context.state.user.isloggedin &&
                          context.isAuthorized(notification.user.id) && (
                            <ListViewItem.Actions>
                              <Action
                                onClick={() =>
                                  context.remove(
                                    "notifications",
                                    notification.id
                                  )
                                }
                              >
                                Löschen
                                <Trash2 size="16px" />
                              </Action>
                            </ListViewItem.Actions>
                          )}
                      </ListViewItem.Content>
                    </ListViewItem>
                  ))}
                </ListView.Items>
                {!notificationFormVisible && (
                  <ListView.Footer>
                    <ActionList secondary="true" noBorder="true">
                      <Action onClick={handleNewNotificationClick}>
                        Mitteilung Hinzufügen <Plus size="16px" />
                      </Action>
                    </ActionList>
                  </ListView.Footer>
                )}
              </>
            )}
            {context.state.notifications.items.length === 0 && (
              <ListView.Empty>
                <div>Keine Mitteilungen</div>
                <div>
                  <Action onClick={handleNewNotificationClick}>
                    <Plus size="16px" />
                    Mitteilung Hinzufügen
                  </Action>
                </div>
              </ListView.Empty>
            )}
          </ListView>
          {notificationFormVisible && (
            <NotificationForm
              onSubmitted={handleNotificationSubmit}
              onCancelled={toggleNotificationFormVisiblity}
            />
          )}
        </div>
      </TypeSection>

      {context.state.events.items.length > 0 && (
        <TypeSection>
          <div>
            <Typo family="Roboto Slab" size="1.8rem" weight="700">
              Veranstaltungen
            </Typo>
            <Typo margin="0.5rem 0 0 0" weight="700" color="#7b7b7b">
              Termine und Veranstaltungen in und zum Thema
              (Gemeinschafts-)Gärten
            </Typo>
          </div>
          <ListView>
            <ListView.Items>
              {context.state.events.items.map((event) => (
                <ListViewItem
                  link
                  as={NavLink}
                  to={`/veranstaltungen/${event.id}`}
                  key={event.id}
                >
                  {event.headerImage ? (
                    <ListViewItem.Header
                      mediaUrl={getThumb(event.headerImage.url, 900)}
                    />
                  ) : (
                    <ListViewItem.Header />
                  )}
                  <ListViewItem.Overlay>
                    <DateOverlay rawDate={event.dateStart} />
                    {event.tbfHosted && <TbfOverlay>TBF</TbfOverlay>}
                  </ListViewItem.Overlay>
                  <ListViewItem.Content>
                    <ListViewItem.Title small>
                      <Typo family="Roboto Slab">{event.name}</Typo>
                    </ListViewItem.Title>
                    <ListViewItem.Text>{event.description}</ListViewItem.Text>
                    <ListViewItem.PropertyList>
                      <Chip>
                        <Tag size="16px" />
                        {event.category}
                      </Chip>
                      <Chip highlighted>
                        <MapPin size="16px" /> {event.locationCity}
                      </Chip>
                    </ListViewItem.PropertyList>
                  </ListViewItem.Content>
                </ListViewItem>
              ))}
            </ListView.Items>
            <ListView.Footer>
              <ActionList secondary="true" noBorder="true">
                <Action to="/veranstaltungen" as={NavLink}>
                  Veranstaltungen
                  <ChevronRight size="16px" />
                </Action>
              </ActionList>
            </ListView.Footer>
          </ListView>
        </TypeSection>
      )}

      {articles.length > 0 && (
        <TypeSection>
          <div>
            <Typo family="Roboto Slab" size="1.8rem" weight="700">
              Blog
            </Typo>
            <Typo margin="0.5rem 0 0 0" weight="700" color="#7b7b7b">
              Praxiswissen und Informationen zum Gründen, Verpachten und
              Organisieren von Gemeinschaftsgärten
            </Typo>
          </div>
          <ListView>
            <ListView.Items>
              {articles.map((article) => (
                <ListViewItem link as="a" href={article.url} key={article.id}>
                  {article.feature_image ? (
                    <ListViewItem.Header mediaUrl={article.feature_image} />
                  ) : (
                    <ListViewItem.Header />
                  )}
                  <ListViewItem.Content>
                    <ListViewItem.Title small>
                      <Typo family="Roboto Slab">{article.title}</Typo>
                    </ListViewItem.Title>
                    <ListViewItem.Text>{article.excerpt}</ListViewItem.Text>
                    <ListViewItem.PropertyList>
                      {article.primary_tag && (
                        <Chip highlighted>
                          <Tag size="16px" />
                          {article.primary_tag.name}
                        </Chip>
                      )}
                    </ListViewItem.PropertyList>
                  </ListViewItem.Content>
                </ListViewItem>
              ))}
            </ListView.Items>
            <ListView.Footer>
              <ActionList secondary="true" noBorder="true">
                <Action href="https://blog.gemeinsam-garteln.tirol/" as="a">
                  Blog
                  <ChevronRight size="16px" />
                </Action>
              </ActionList>
            </ListView.Footer>
          </ListView>
        </TypeSection>
      )}
    </Page>
  );
}

export default Subscribe(HomeView, { context: HubStateContainer });
