import React, { Component } from "react";
import { MapPin, Tag, Home } from "react-feather";
import Subscribe from "unstated-subscribe-hoc";
import getThumb from "../../data/CloudinaryProvider";

import HubStateContainer from "../../HubStateContainer";

import Page from "../../ui/web/Page";
import Chip from "../../ui/Chip";
import ActionLinkText from "../../ui/ActionLinkText";
import Typo from "../../ui/Typo";
import View from "../../ui/View";

class EventDetailView extends Component {
  constructor(props) {
    super(props);

    this.state = { isLoaded: false };
  }

  componentDidMount() {
    let id = this.props.match.params.id;
    this.props.context.sync("events", id).then(() => {
      this.setState({ isLoaded: true });
    });
  }

  componentWillUnmount() {
    this.props.context.unsync("events");
  }

  renderDate(dateStartRaw, dateEndRaw) {
    let locale = "de-DE";
    let dateStart = new Date(dateStartRaw);
    let dateStartMonth = dateStart.toLocaleString(locale, { month: "long" });

    if (dateEndRaw !== null) {
      let dateEnd = new Date(dateEndRaw);
      let dateEndMonth = dateEnd.toLocaleString(locale, { month: "long" });
      return (
        <Typo size="1.2rem" weight="bold">
          {dateStart.getDate()}&nbsp;{dateStartMonth}&nbsp;-&nbsp;
          {dateEnd.getDate()}&nbsp;{dateEndMonth}
        </Typo>
      );
    }

    return (
      <Typo size="1.2rem" weight="bold">
        {dateStart.getDate()}&nbsp;{dateStartMonth}
      </Typo>
    );
  }

  render() {
    const {
      context: {
        state: {
          events: { selectedItem }
        }
      }
    } = this.props;

    const chips = [
      {
        label: selectedItem.category,
        icon: <Tag size="16px" />
      },
      {
        label: selectedItem.locationCity,
        icon: <MapPin size="16px" />,
        highlighted: true
      }
    ];

    if (selectedItem.locationPlace) {
      chips.push({
        label: selectedItem.locationPlace,
        icon: <Home size="16px" />,
        highlighted: true
      });
    }

    return (
      <Page
        header={{
          text: selectedItem.name,
          bgimg: selectedItem.headerImage
            ? getThumb(selectedItem.headerImage.url, 1920)
            : null,
          subtext: this.renderDate(
            selectedItem.dateStart,
            selectedItem.dateEnd
          ),
          overlay: true,
          heroheader: true
        }}
        chips={chips}
      >
        {this.state.isLoaded && (
          <>
            <View withAside>
              <div>
                <View.Section>
                  <View.TextArea>{selectedItem.description}</View.TextArea>
                </View.Section>

                {selectedItem.contact && (
                  <View.Section>
                    <View.SectionHeader>
                      <Chip>Kontakt</Chip>
                    </View.SectionHeader>
                    <div>{selectedItem.contact}</div>
                  </View.Section>
                )}

                {selectedItem.links.length > 0 && (
                  <View.Section>
                    <View.SectionHeader>
                      <Chip>Links</Chip>
                    </View.SectionHeader>

                    {selectedItem.links.map(link => (
                      <div key={link.description}>
                        <ActionLinkText href={link.url}>
                          {link.description}
                        </ActionLinkText>
                      </div>
                    ))}
                  </View.Section>
                )}
              </div>

              <View.SecondaryContent>
                {selectedItem.tbfHosted && (
                  <View.Section>
                    <div>TBF Veranstaltung</div>
                  </View.Section>
                )}

                <View.Section>
                  <View.SectionHeader>
                    <Chip>Zeitplan</Chip>
                  </View.SectionHeader>
                  <div>{selectedItem.schedule}</div>
                </View.Section>

                {selectedItem.registration && (
                  <View.Section>
                    <View.SectionHeader>
                      <Chip>Anmeldung</Chip>
                    </View.SectionHeader>
                    <div>{selectedItem.registration}</div>
                  </View.Section>
                )}

                {selectedItem.costs && (
                  <View.Section>
                    <View.SectionHeader>
                      <Chip>Kosten</Chip>
                    </View.SectionHeader>
                    <div>{selectedItem.costs}</div>
                  </View.Section>
                )}

                {selectedItem.audience && (
                  <View.Section>
                    <View.SectionHeader>
                      <Chip>Zielgruppe</Chip>
                    </View.SectionHeader>
                    <div>{selectedItem.audience}</div>
                  </View.Section>
                )}
              </View.SecondaryContent>
            </View>
          </>
        )}
      </Page>
    );
  }
}

export default Subscribe(EventDetailView, { context: HubStateContainer });

/*
            <Header>
              <Header.Content>
                {this.renderDate(selectedItem.dateStart, selectedItem.dateEnd)}
                <Header.Title>{selectedItem.name}</Header.Title>
                <Header.PropertyList>
                  <Chip>
                    <Tag size="16px" />
                    {selectedItem.category}
                    {selectedItem.tbfHosted && (
                      <span>,&nbsp;TBF Veranstaltung</span>
                    )}
                  </Chip>
                  <Chip highlighted>
                    <MapPin size="16px" />
                    {selectedItem.locationCity}
                  </Chip>
                  {selectedItem.locationPlace && (
                    <Chip highlighted>
                      <Home size="16px" />
                      {selectedItem.locationPlace}
                    </Chip>
                  )}
                </Header.PropertyList>
              </Header.Content>
            </Header>

*/
