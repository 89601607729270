import React from 'react';
import { Route, Switch } from 'react-router-dom';
import Subscribe from 'unstated-subscribe-hoc';

import HubScaffold from './ui/HubScaffold';
import Logo from './assets/tyg-logo.svg';
import HubStateContainer from './HubStateContainer';

import HomeView from './views/home/HomeView';
import GardenView from './views/garden/GardenView';
import GardenDetailView from './views/garden/GardenDetailView';
import GardenFormView from './views/garden/GardenFormView';
import EventsView from './views/event/EventsView';
import EventDetailView from './views/event/EventDetailView';
import EventFormView from './views/event/EventFormView';
import LoginForm from './views/auth/LoginForm';
import SignupForm from './views/auth/SignupForm';
import Imprint from './views/imprint/ImprintView';
import ServicePoint from './views/servicePoint/ServicePoint'
import PasswordResetForm from './views/auth/PasswordResetForm';
import PasswordConfirmForm from './views/auth/PasswordConfirmForm';

const Links = [
  { name: 'Gärten', url: '/gaerten' },
  { name: 'Veranstaltungen', url: '/veranstaltungen' },
  { name: 'Servicestelle', url: '/servicestelle' },
  { name: 'Tipps & Infos', url: 'https://blog.gemeinsam-garteln.tirol/', external: true },
];

function Hub ({ context }) {
  return (
    <HubScaffold links={Links} logo={Logo} user={context.state.user}>
      <Switch>
        <Route exact path='/' component={HomeView} />
        <Route exact path='/login' component={LoginForm} />
        <Route exact path='/signup' component={SignupForm} />
        <Route exact path='/pwdreset' component={PasswordResetForm} />
        <Route exact path='/pwdresetconfirm' component={PasswordConfirmForm} />


        <Route exact path='/gaerten' component={GardenView} />
        <Route exact path='/gaerten/neu' component={GardenFormView} />
        <Route exact path='/gaerten/:id' component={GardenDetailView} />
        <Route exact path='/gaerten/:id/bearbeiten' component={GardenFormView} />

        <Route exact path='/veranstaltungen' component={EventsView} />
        <Route exact path='/veranstaltungen/neu' component={EventFormView} />
        <Route exact path='/veranstaltungen/:id' component={EventDetailView} />
        <Route exact path='/veranstaltungen/:id/bearbeiten' component={EventFormView} />

        <Route exact path='/impressum' component={Imprint} />
        <Route exact path='/servicestelle' component={ServicePoint} />
      </Switch>
    </HubScaffold>
  );
}

export default Subscribe(Hub, { context: HubStateContainer });
