import styled from "styled-components";

const SideBar = styled.div`
  position: fixed;
  display: flex;
  flex-flow: column;
  z-index: 10;

  top: 0;
  bottom: 0;
  left: -250px;
  width: 250px;
  height: 100%;
  padding: 8rem 2rem 2rem 2rem;
  background-color: #ececec;
  transition: all 0.4s ease;
  transform: ${props => (props.open ? "translateX(250px)" : "translateX(0)")};
`;

export default SideBar;

/*
transform: ${props=>props.open ? "translateX(250px)" : "translateX(0)"};
*/
