import React from "react";
import styled from "styled-components";

const DateTemplate = styled.div`
  display: flex;
  flex-direction: column;
  background-color: white;
  padding: 0.7rem;
  width: 60px;
  height: 60px;
  justify-content: center;
  align-items: center;
`;

const Day = styled.div`
  font-size: 1.5rem;
  font-weight: bold;
  line-height: 1;
`;

const Month = styled.div`
  text-transform: uppercase;
  line-height: 1;
`;

function DateOverlay({ rawDate }) {
  let date = new Date(rawDate);
  let locale = "de-DE";
  let month = date.toLocaleString(locale, { month: "short" });
  return (
    <DateTemplate>
      <Day>{date.getDate()}</Day>
      <Month>{month}</Month>
    </DateTemplate>
  );
}

export default DateOverlay;
