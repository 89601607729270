import styled from "styled-components";

const Typo = styled.div`
  font-family: ${props => props.family || props.theme.view.font.family};
  font-size: ${props => props.size || props.theme.view.font.size};
  font-weight: ${props => props.weight || props.theme.view.font.weight};
  color: ${props => props.color || props.theme.view.color.foreground};
  margin: ${props => props.margin || "0"};
`;

const Header = styled.h2`
  font-size: 1.2rem;
  margin: 0 0 1rem 0;
`;

const Header2 = styled.h2`
  font-size: 1rem;
  margin: 0 0 1rem 0;
  font-weight: 700;
`;

const Paragraph = styled.p`
  margin:${props => props.noBorder ? 0 : " 0 0 1rem 0"};
`;

const List = styled.ul`
margin-top:0;
margin-bottom:1rem;
padding-left:0;
`;

const ListItem = styled.li`
margin-bottom:0.3rem;
`;

export default Typo;
export { Header, Header2, Paragraph, List, ListItem };
