import styled from "styled-components";

const ActionLinkText = styled.a`
  display: inline-block;
  font-family: inherit;
  font-size: inherit;
  line-height: inherit;
  font-weight: bold;
  color: inherit;

  text-decoration: none;

  transition: 0.2s ease-in-out;

  cursor: pointer;

  :hover {
    font-weight: bold;
    color: inherit;
    text-decoration: underline;
  }
  :focus {
    outline: none;
  }

  > svg {
    margin-left: 0.2rem;
  }
`;

export default ActionLinkText;
