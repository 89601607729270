import { createGlobalStyle } from "styled-components";

const ViewBreakpoint = {
  s: "(min-width: 700px)",
  m: "(min-width: 1000px)",
  l: "(min-width: 1200px)",
  xl: "(min-width: 1400px)",
  xxl: "(min-width: 1500px)",
};

const Theme = {
  view: {
    font: {
      family: `-apple-system, BlinkMacSystemFont,
    “Segoe UI”, “Roboto”, “Oxygen”,
    “Ubuntu”, “Cantarell”, “Fira Sans”,
    “Droid Sans”, “Helvetica Neue”, sans-serif;`,
      size: "1rem",
      weight: "400"
    },
    color: { foreground: "#404040", background: "#F9FAF7", accent: "#93BD6B" }
  },
  section: "1400px",
  color: {
    background: "#F9FAF7",
    contentBackground: "#f8f8f8",
    highlighted: "#93BD6B"
  }
};

const GlobalStyle = createGlobalStyle`
*,
*::before,
*::after {
box-sizing: border-box;
}

html {
font-family:sans-serif;
line-height: 1.15;
}

body {
margin: 0;
padding: 0;

font-family: ${Theme.view.font.family};
font-size: ${Theme.view.font.size};
font-weight: ${Theme.view.font.weight};
line-height: 1.5;
color: ${Theme.view.color.foreground};

overflow-x: hidden;

a{
    color: inherit;
    text-decoration:none;
}

a:hover {text-decoration:none;color:inherit;}


svg, svg symbol {
overflow: visible;
}


::after{display:none}
}
`;

export { Theme, GlobalStyle, ViewBreakpoint };
