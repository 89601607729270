import styled, { css } from "styled-components";
import ActionBase from "./primitives/ActionBase";

const Action = styled.button`
  ${ActionBase}

  ${props =>
    props.buttonStyle &&
    css`
      height: 2.5rem;
      padding: 0.4rem;
      border: 2px solid black;

      :hover {
        background-color: black;
        color: white;
      }

      :disabled {
        cursor: default;
        opacity: 0.5;
        pointer-events: none;
      }
    `}
`;

export default Action;
