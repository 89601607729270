import React from "react";
import styled, { css } from "styled-components";
import { ViewBreakpoint } from "../Theme";

import Typo from "../Typo";
import Section from "../primitives/Section";
import Chip from "../Chip";

const HeaderContainer = styled.div`
  margin-top: 100px;
  @media ${ViewBreakpoint["s"]} {
    margin-top: 150px;
  }
`;

const HeaderBackground = styled.div`
  ${(props) =>
    props.bgimg &&
    css`
      background-image: url(${props.bgimg});
      background-repeat: no-repeat;
      background-size: cover;
      background-position: center top;
    `}
`;

const HeaderContent = styled.div`
  display: flex;
  position: relative;
  width: 100%;
  flex-direction: column;
  justify-content: flex-end;
  min-height: ${(props) => (props.hero ? "500px" : "120px")};
  @media ${ViewBreakpoint["s"]} {
    justify-content: center;
    min-height: ${(props) => (props.hero ? "600px" : "220px")};
  }
`;

const Header = styled.div`
  ${(props) =>
    props.overlay &&
    css`
      position: absolute;
      padding: 2rem 1rem;
      margin: 0;
      background-color: white;
      z-index: 2;
      width: 100%;
      @media ${ViewBreakpoint["s"]} {
        padding: 5rem 3rem;
        margin: 1rem;
        width: initial;
      

      }
    `}
`;

const HeaderText = styled(Typo)`
  font-family: "Roboto Slab";
  font-size: 1.6rem;
  line-height: 1.2;
  max-width: 500px;
  font-weight: bold;
  @media ${ViewBreakpoint["s"]} {
    font-size: 2rem;
  }
`;

const SubHeader = styled(Typo)`
  margin: 1rem 0 0 0;
  max-width: 500px;
`;

const ChipList = styled.div`
  font-size: 0.9rem;
  color: #7b7b7b;
  margin-top: 2rem;
  > * {
    margin-bottom: 0.2rem;
  }
`;

const ContentContainer = styled.div`
  background-color: white;
  padding: 2rem 0;
`;

function Page({ header, customSubheader, chips, customContent, children }) {
  return (
    <>
      {header ? (
        <HeaderContainer>
          <Section>
            <HeaderBackground bgimg={header.bgimg}>
              <HeaderContent hero={header.heroheader}>
                {customContent}
                <Header overlay={header.overlay}>
                  {header.subtext}
                  <HeaderText>{header.text}</HeaderText>
                  <SubHeader>{header.subheader}</SubHeader>
                  {customSubheader}
                  {chips && (
                    <ChipList>
                      {chips.map((chip) => (
                        <Chip highlighted={chip.highlighted}>
                          {chip.icon}
                          {chip.label}
                        </Chip>
                      ))}
                    </ChipList>
                  )}
                </Header>
              </HeaderContent>
            </HeaderBackground>
          </Section>
        </HeaderContainer>
      ) : (
        <HeaderContainer />
      )}

      <ContentContainer>
        <Section>
          <div>{children}</div>
        </Section>
      </ContentContainer>
    </>
  );
}

export default Page;
