import React, { useState } from "react";

import ReactMapGL, { NavigationControl, Marker } from "react-map-gl";

const mapboxAccessToken =
  "pk.eyJ1Ijoibjd0cmQiLCJhIjoiY2s2OW9jM3J3MDRtMjNlcXMxbDdhbzZ1dCJ9.6icFK0xFHbepgHgFJmDC0Q";

const mapboxStyle = "mapbox://styles/n7trd/ck69olbyr0udi1imr9jke5gmm";

function GardenMap({ mapViewport, gardenLocations }) {
  const [viewport, setViewport] = useState(mapViewport);

  return (
    <ReactMapGL
      {...viewport}
      mapStyle={mapboxStyle}
      mapboxApiAccessToken={mapboxAccessToken}
      onViewportChange={(viewport) => setViewport(viewport)}
      attributionControl={false}
      scrollZoom={false}
      dragPan={true}
    >
      <div style={{ position: "absolute", right: 0 }}>
        <NavigationControl />
      </div>

      {gardenLocations.length &&
        gardenLocations.map((location) => (
          <Marker
            key={location[0]}
            latitude={location[0]}
            longitude={location[1]}
          >
            <svg xmlns="http://www.w3.org/2000/svg" width="10px" height="10px">
              <circle r="5" fill="#93BD6B" />
            </svg>
          </Marker>
        ))}
    </ReactMapGL>
  );
}

function MapInput({ location, mapViewport, ...props }) {
  const [viewport, setViewport] = useState(mapViewport);
  const [_location, _setLocation] = useState(location);

  const onLocationChanged = (lngLat) => {
    _setLocation([lngLat[1],lngLat[0]]);
    props.locationChanged([lngLat[1],lngLat[0]]);
  };

  return (
    <ReactMapGL
      {...viewport}
      mapStyle={mapboxStyle}
      mapboxApiAccessToken={mapboxAccessToken}
      onViewportChange={(viewport) => setViewport(viewport)}
      attributionControl={false}
      onClick={({ lngLat }) => onLocationChanged(lngLat)}
    >
      {_location.length > 0 && (
        <Marker
          latitude={_location[0]}
          longitude={_location[1]}
          onDragEnd={({ lngLat }) => onLocationChanged(lngLat)}
          offsetLeft={0} offsetTop={0}
          draggable
        >
          <svg xmlns="http://www.w3.org/2000/svg" width="20px" height="20px">
            <circle r="10" fill="#93BD6B" />
          </svg>
        </Marker>
      )}
    </ReactMapGL>
  );
}

export { GardenMap, MapInput };
