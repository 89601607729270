import styled from "styled-components";
import { ViewBreakpoint } from "../Theme";

const Section = styled.div`
  margin: 0 auto;
  padding: 0 5%;
  max-width: ${props => props.theme.section};

  @media ${ViewBreakpoint["xxl"]} {
    padding: 0;
  }

`;

export default Section;