const FieldCategories = [
  "Allgemeine Veranstaltung",
  "Vortrag",
  "Treffen/Diskussion",
  "Workshop",
  "Fest",
  "Exkursion",
  "Info-Veranstaltung"
];

const EventType = {
  name: "",
  headerImage: null,
  category: FieldCategories[0],
  description: "",
  tbfHosted: false,
  locationCity: "",
  locationPlace: "",
  dateStart: "",
  dateEnd: "",
  schedule: "",
  costs: "",
  registration: "",
  audience: "",
  contact: "",
  links: []
};

const fromRestFormat = data => {
  const fieldsToRemove = ["id", "_id", "createdAt", "updatedAt", "__v"];
  fieldsToRemove.forEach(field => delete data[field]);

  if (!Array.isArray(data.links)) {
    data.links = [];
  }

  data.dateStart = data.dateStart.substring(0, 10);

  if (data.dateEnd) {
    data.dateEnd = data.dateEnd.substring(0, 10);
  } else {
    data.dateEnd = "";
  }

  return data;
};

export { EventType, FieldCategories, fromRestFormat };
