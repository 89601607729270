import React, { useState } from "react";

import { Plus, XCircle } from "react-feather";
import { Label, TextArea, Select } from "../../ui/Form";
import Action from "../../ui/Action";
import { Form } from "../../ui/Form";
import { InputGroup } from "../../ui/Input";

import { NotificationCategory, NotificationType } from "./NotificationType";

function NotificationForm(props) {
  const [formData, setFormData] = useState(NotificationType);

  const formDataChanged = (field, value) => {
    let newState = { ...formData };
    newState[field] = value;
    setFormData(newState);
  };

  const handleInputChange = (event) => {
    formDataChanged(event.target.name, event.target.value);
  };

  const handleSubmit = (event) => {
    event.preventDefault();
    props.onSubmitted(formData);
  };

  const handleCancelled = () => {
    props.onCancelled();
  };

  return (
    <Form inline onSubmit={handleSubmit}>
      <InputGroup columns="1fr">
        <div>
          <Label htmlFor="category">Kategorie</Label>
          <Select
            name="category"
            fullWidth
            value={formData.category}
            onChange={handleInputChange}
            required
          >
            {NotificationCategory.map((field) => (
              <option key={field} value={field}>
                {field}
              </option>
            ))}
          </Select>
        </div>
        <div>
          <Label htmlFor="contact">Kontakt (optional)</Label>
          <TextArea
            name="contact"
            value={formData.contact}
            onChange={handleInputChange}
            type="text"
            rows="3"
            maxlength="140"
          />
        </div>
        <div>
          <Label htmlFor="text">Mitteilung</Label>
          <TextArea
            value={formData.text}
            onChange={handleInputChange}
            name="text"
            rows="4"
            maxlength="140"
            required
          />
        </div>
      </InputGroup>

      <Form.Actions noBorder>
        <Action onClick={handleCancelled}>
          Abbrechen
          <XCircle size="16px" />
        </Action>
        <Action buttonStyle type="submit" value="Submit">
          Hinzufügen
          <Plus size="16px" />
        </Action>
      </Form.Actions>
    </Form>
  );
}

export default NotificationForm;
