import styled from "styled-components";

const ChoiceChip = styled.div`
  display: inline-flex;
  flex-direction: row;
  align-items: center;
  justify-content: center;

  height: 2.5rem;
  padding: 0.4rem 0;

  font-family: inherit;
  font-size: inherit;

  color: inherit;
  line-height: 1.5;

  user-select: none;
  cursor: pointer;

  border-bottom: 2px solid transparent;

  border-color: ${props => (props.selected ? "black" : "transparent")};
  transition: 0.6s;

  > svg {
    margin-left: 0.3rem;
  }

  :hover {
    border-bottom: 2px solid #c0c0c0;
  }
`;

export default ChoiceChip;

//min-width ?!
//no warp ?!
