import React, { useState, useEffect } from "react";
import Subscribe from "unstated-subscribe-hoc";

import HubStateContainer from "../../HubStateContainer";

import EventForm from "./EventForm";
import { EventType, fromRestFormat } from "./EventType";
import Page from "../../ui/web/Page";

function EventFormView({ context, ...props }) {
  const type = "events";
  const typeId = props.match.params.id || null;
  const typeData = context.state[type].selectedItem;
  const hasTypeId = typeId ? true : false;

  const [dataLoaded, setDataLoaded] = useState(false);
  //const [dataUploading, setDataUploading] = useState(false);

  useEffect(() => {
    setDataLoaded(false);
    if (hasTypeId) {
      context.sync(type, typeId).then(() => {
        setDataLoaded(true);
      });
    }

    return () => {
      context.unsync(type);
    };
  }, [context, type, typeId, hasTypeId]);

  const uploadFile = (id, formFiles) => {
    if (formFiles.headerImage) {
      //setDataUploading(true);
      formFiles.headerImage.append("refId", id);
      formFiles.headerImage.append("ref", "event");
      formFiles.headerImage.append("field", "headerImage");
      console.log("Uploading File...");
      context.upload(formFiles.headerImage);
    }
  };

  const handleFormSubmission = (formData, formFiles) => {
    if (typeId) {
      context.update(type, typeId, formData).then(updatedType => {
        uploadFile(updatedType.id, formFiles);
        props.history.goBack();
      });
    } else {
      context.add(type, formData).then(addedType => {
        uploadFile(addedType.id, formFiles);
        props.history.goBack();
      });
    }
  };

  const handleFormCancellation = () => {
    props.history.goBack();
  };

  return (
    <Page>
      {!typeId && (
        <EventForm
          typeData={EventType}
          onSubmitted={handleFormSubmission}
          onCancelled={handleFormCancellation}
        />
      )}

      {hasTypeId && dataLoaded && (
        <EventForm
          typeData={fromRestFormat(typeData)}
          onSubmitted={handleFormSubmission}
          onCancelled={handleFormCancellation}
        />
      )}
    </Page>
  );
}

export default Subscribe(EventFormView, { context: HubStateContainer });
