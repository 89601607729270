import React, { useState, useRef } from "react";
import getThumb from "../../data/CloudinaryProvider";

import { Plus, XCircle, Trash2 } from "react-feather";

import { InputGroup } from "../../ui/Input";
import Image from "../../ui/Image";
import Action from "../../ui/Action";
import ActionLinkText from "../../ui/ActionLinkText";
import { Form, TextInput, TextArea, Select, Label } from "../../ui/Form";

import { MapInput } from "./GardenMap";

import {
  FieldSpecialCategory,
  FieldAccessKind,
  FieldCultivationType,
  FieldPatchAvailability,
  FieldLocationDistrict,
} from "./GardenType";

const mapViewport = {
  width: "100%",
  height: "300px",
  latitude: 47.016,
  longitude: 10.642,
  zoom: 7,
};

function GardenForm(props) {
  const [formData, setFormData] = useState(props.typeData);
  const headerImageInputData = useRef(null);

  const formDataChanged = (field, value) => {
    const newState = { ...formData };
    newState[field] = value;
    setFormData(newState);
  };

  const handleToggleChange = (event) => {
    formDataChanged(event.target.name, !formData[event.target.name]);
  };

  const handleInputChange = (event) => {
    formDataChanged(event.target.name, event.target.value);
  };

  const handleLinkChange = (changedIndex, field) => (event) => {
    const newLinks = formData.links.map((link, linkIndex) => {
      if (changedIndex !== linkIndex) return link;
      return { ...link, [field]: event.target.value };
    });

    formDataChanged("links", newLinks);
  };

  const handleLinkAdd = (event) => {
    event.preventDefault();
    formDataChanged(
      "links",
      formData.links.concat([{ description: "", url: "" }])
    );
  };

  const handleLinkRemove = (index) => (event) => {
    event.preventDefault();
    formDataChanged(
      "links",
      formData.links.filter((s, sidx) => index !== sidx)
    );
  };

  const handleHeaderImageRemoved = (event) => {
    event.preventDefault();
    formDataChanged("headerImage", null);
  };

  const handleSubmit = (event) => {
    event.preventDefault();

    const formFiles = { headerImage: null };

    if (headerImageInputData.current) {
      if (headerImageInputData.current.files[0]) {
        const fileFormData = new FormData();
        fileFormData.append("files", headerImageInputData.current.files[0]);
        formFiles.headerImage = fileFormData;
      }
    }

    props.onSubmitted(formData, formFiles);
  };

  const handleCancelled = () => {
    props.onCancelled();
  };

  return (
    <Form onSubmit={handleSubmit}>
      <Form.Header>Neuer Garten</Form.Header>
      <Form.Contents>
        <Form.PrimaryContent>
          <InputGroup columns="2fr 1fr">
            <div>
              <Label htmlFor="name">Titel *</Label>
              <TextInput
                type="text"
                name="name"
                value={formData.name}
                onChange={handleInputChange}
                required
              />
            </div>
            <div>
              <Label htmlFor="specialCategory">Kategorie *</Label>
              <Select
                fullWidth
                name="specialCategory"
                value={formData.specialCategory}
                onChange={handleInputChange}
                required
              >
                {FieldSpecialCategory.map((sp) => (
                  <option key={sp} value={sp}>
                    {sp}
                  </option>
                ))}
              </Select>
            </div>
          </InputGroup>

          <InputGroup columns="1fr">
            <div>
              <Label htmlFor="description">Beschreibung *</Label>
              <TextArea
                name="description"
                value={formData.description}
                onChange={handleInputChange}
                rows="20"
                required
              />
            </div>
          </InputGroup>

          {formData.links.map((link, index) => (
            <InputGroup columns="1fr 2fr auto" key={index}>
              <div>
                <Label>Beschreibung</Label>
                <TextInput
                  value={link.description}
                  onChange={handleLinkChange(index, "description")}
                />
              </div>
              <div>
                <Label>Url</Label>
                <TextInput
                  value={link.url}
                  onChange={handleLinkChange(index, "url")}
                />
              </div>
              <Action onClick={handleLinkRemove(index)}>
                <Trash2 size="16px" />
              </Action>
            </InputGroup>
          ))}

          <InputGroup columns="1fr" items="start">
            <Action onClick={handleLinkAdd}>
              Link Hinzufügen
              <Plus size="16px" />
            </Action>
          </InputGroup>

          <InputGroup columns="1fr 1fr">
            <div>
              <Label htmlFor="locationDistrict">Bezirk *</Label>
              <Select
                fullWidth
                name="locationDistrict"
                value={formData.locationDistrict}
                onChange={handleInputChange}
                required
              >
                <option value="">-- Bezirk auswählen --</option>
                <optgroup label="Tirol">
                  {FieldLocationDistrict.slice(0, 9).map((sp) => (
                    <option key={sp} value={sp}>
                      {sp}
                    </option>
                  ))}
                </optgroup>
                <optgroup label="Südtirol">
                  {FieldLocationDistrict.slice(9, 17).map((sp) => (
                    <option key={sp} value={sp}>
                      {sp}
                    </option>
                  ))}
                </optgroup>
              </Select>
            </div>
            <div>
              <Label htmlFor="locationCity">Stadt *</Label>
              <TextInput
                type="text"
                name="locationCity"
                value={formData.locationCity}
                onChange={handleInputChange}
                required
              />
            </div>
            <div>
              <Label htmlFor="locationPlace">Ort</Label>
              <TextInput
                type="text"
                name="locationPlace"
                value={formData.locationPlace}
                onChange={handleInputChange}
              />
            </div>
          </InputGroup>

          <InputGroup columns="1fr">
            <div>
              <MapInput
                mapViewport={mapViewport}
                location={formData.locationLatLng}
                locationChanged={(location) =>
                  formDataChanged("locationLatLng", location)
                }
              />
            </div>
            <div>
              <Label htmlFor="locationLatLng">Koordinaten</Label>
              <TextInput
                type="text"
                name="locationLatLng"
                value={formData.locationLatLng}
                onChange={handleInputChange}
                disabled
              />
            </div>
          </InputGroup>

          <InputGroup columns="1fr 1fr">
            <div>
              <Label htmlFor="contact">Kontakt</Label>
              <TextInput
                type="text"
                name="contact"
                value={formData.contact}
                onChange={handleInputChange}
              />
            </div>
            <div>
              <Label htmlFor="operator">Betreiber</Label>
              <TextInput
                type="text"
                name="operator"
                value={formData.operator}
                onChange={handleInputChange}
              />
            </div>
          </InputGroup>

          <InputGroup columns="1fr 2fr">
            <div>
              <Label htmlFor="accessKind">Zugang *</Label>
              <Select
                fullWidth
                name="accessKind"
                value={formData.accessKind}
                onChange={handleInputChange}
                required
              >
                <option value="">-- Zugang auswählen --</option>
                {FieldAccessKind.map((ac) => (
                  <option key={ac} value={ac}>
                    {ac}
                  </option>
                ))}
              </Select>
            </div>
            <div>
              <Label htmlFor="accessInformation">Zugangsinformationen</Label>
              <TextInput
                type="text"
                name="accessInformation"
                value={formData.accessInformation}
                onChange={handleInputChange}
              />
            </div>
          </InputGroup>

          <InputGroup columns="1fr">
            <div>
              <Label htmlFor="costs">Kosten</Label>
              <TextArea
                name="costs"
                value={formData.costs}
                onChange={handleInputChange}
                rows="5"
              />
            </div>
          </InputGroup>

          <InputGroup columns="1fr">
            <div>
              <Label htmlFor="registration">Anmeldung</Label>
              <TextArea
                name="registration"
                value={formData.registration}
                onChange={handleInputChange}
                rows="5"
              />
            </div>
          </InputGroup>
        </Form.PrimaryContent>
        <Form.SecondaryContent>
          <InputGroup columns="1fr">
            <Label htmlFor="headerImage">Titelbild</Label>
            {formData.headerImage ? (
              <div>
                <div>
                  <Image src={getThumb(formData.headerImage.url, 900)} />
                </div>
                <div>
                  <Action onClick={handleHeaderImageRemoved}>
                    <Trash2 size="16px" />
                  </Action>
                </div>
              </div>
            ) : (
              <input
                name="headerImage"
                type="file"
                ref={headerImageInputData}
              />
            )}
          </InputGroup>

          <InputGroup columns="1fr">
            <div>
              <Label htmlFor="patchAvailability">Gartenplatz *</Label>
              <Select
                fullWidth
                name="patchAvailability"
                value={formData.patchAvailability}
                onChange={handleInputChange}
                required
              >
                <option value="">-- Verfügbarkeit auswählen --</option>
                {FieldPatchAvailability.map((ac) => (
                  <option key={ac} value={ac}>
                    {ac}
                  </option>
                ))}
              </Select>
            </div>
          </InputGroup>

          <InputGroup columns="1fr">
            <div>
              <Label htmlFor="cultivationType">Anbauart *</Label>
              <Select
                fullWidth
                name="cultivationType"
                value={formData.cultivationType}
                onChange={handleInputChange}
                required
              >
                <option value="">-- Anbauart auswählen --</option>
                {FieldCultivationType.map((ac) => (
                  <option key={ac} value={ac}>
                    {ac}
                  </option>
                ))}
              </Select>
            </div>
          </InputGroup>

          <InputGroup columns="1fr 1fr">
            <div>
              <Label htmlFor="patchSizeMin">min (qm)</Label>
              <TextInput
                type="text"
                name="patchSizeMin"
                value={formData.patchSizeMin}
                onChange={handleInputChange}
              />
            </div>
            <div>
              <Label htmlFor="patchSizeMax">max (qm)</Label>
              <TextInput
                type="text"
                name="patchSizeMax"
                value={formData.patchSizeMax}
                onChange={handleInputChange}
              />
            </div>
          </InputGroup>

          <InputGroup columns="1fr 1fr">
            <div>
              <Label htmlFor="foundingYear">Gründungsjahr (JJJJ)</Label>
              <TextInput
                type="text"
                name="foundingYear"
                value={formData.foundingYear}
                onChange={handleInputChange}
              />
            </div>
            <div>
              <Label htmlFor="memberCount">Mitglieder</Label>
              <TextInput
                type="text"
                name="memberCount"
                value={formData.memberCount}
                onChange={handleInputChange}
              />
            </div>
            <div>
              <Label htmlFor="size">Flächengröße (qm)</Label>
              <TextInput
                type="text"
                name="size"
                value={formData.size}
                onChange={handleInputChange}
              />
            </div>
          </InputGroup>

          <InputGroup columns="1fr">
            <div>
              <Label htmlFor="audience">Zielgruppe</Label>
              <TextInput
                type="text"
                name="audience"
                value={formData.audience}
                onChange={handleInputChange}
              />
            </div>
          </InputGroup>

          <InputGroup columns="1fr">
            <div>
              <Label htmlFor="services">Besondere Angebote</Label>
              <TextInput
                type="text"
                name="services"
                value={formData.services}
                onChange={handleInputChange}
              />
            </div>
          </InputGroup>

          <InputGroup columns="1fr">
            <div>
              <Label>
                <input
                  type="checkbox"
                  name="allowLinking"
                  checked={formData.allowLinking}
                  onChange={handleToggleChange}
                />
                (Optional) Ich bin damit einverstanden, dass mein Garten auch
                auf der österreichischen Gartenkarte beim &nbsp;
                <ActionLinkText
                  href="https://www.gartenpolylog.org"
                  target="_blank"
                >
                  Gartenpolylog
                </ActionLinkText>
                &nbsp;eintragen wird.
              </Label>
            </div>
          </InputGroup>
        </Form.SecondaryContent>
      </Form.Contents>
      <Form.Actions>
        <Action onClick={handleCancelled}>
          Abbrechen
          <XCircle size="16px" />
        </Action>
        <Action buttonStyle type="submit" value="Submit">
          Abschicken
          <Plus size="16px" />
        </Action>
      </Form.Actions>
    </Form>
  );
}

export default GardenForm;
