import React, { Component } from "react";
import styled from "styled-components";
import { NavLink } from "react-router-dom";
import { Menu, ChevronRight } from "react-feather";

import Subscribe from "unstated-subscribe-hoc";
import HubStateContainer from "../HubStateContainer";

import SideBar from "./SideBar";
import LinkList from "./LinkList";
import Action from "./Action";
import LinkReset from "./primitives/LinkReset";
import Typo from "./Typo";
import PropertyItem from "./PropertyItem";
import Image from "./Image";
import Section from "./primitives/Section";

import TbfLogo from "../assets/tbf-logo.png";

const Header = styled.div`
  padding: 2rem 0;
  position: absolute;
  width: 100%;
  z-index: 1000;
`;

const HeaderBar = styled.div`
  @media (min-width: 700px) {
    display: none;
  }
`;

const HeaderBarExtended = styled.div`
  display: none;
  font-weight: bold;
  @media (min-width: 700px) {
    display: flex;
    flex-direction: row;
    align-items: center;
  }
`;

const Footer = styled.div`
  padding-top: 1rem;
  border-top: 1px solid #bbbbbb;
  background-color: white;
`;

const FooterInfo = styled.div`
  display: grid;
  grid-gap: 1rem;
  grid-template-columns: 1fr;
  color: inherit;

  @media (min-width: 1000px) {
    grid-template-columns: 1fr 1fr 1fr;
  }
`;

const FooterInfoSection = styled.div`
  border-bottom: 1px solid #ececec;
  padding: 1rem 0;
  @media (min-width: 700px) {
    padding: 2rem;
    border-bottom: none;
    :nth-child(2) {
      border-left: 1px solid #ececec;
      border-right: 1px solid #ececec;
    }
  }
`;

const MenuButtonStyled = styled.button`
  padding: 6px;
  background-color: transparent;
  color: gray;
  border: none;
  :focus {
    outline: none;
  }
`;

const Logo = styled.div`
  width: 80px;
  height: 80px;
  margin: 0 auto;

  @media (min-width: 700px) {
    margin: 0;
  }
`;

const LogoLink = styled(NavLink)`
  ${LinkReset}
`;

const TbfLogoStyled = styled.a`
  ${LinkReset}
  display:block;
  width: 150px;
`;

const LoginButtonContainerMobile = styled.div`
  margin-top: 1rem;
`;

const MenuButton = (props) => {
  return (
    <MenuButtonStyled {...props}>
      <Menu size="24px" />
    </MenuButtonStyled>
  );
};

const HubScaffoldStyled = styled.div`
  overflow-x: hidden;
  position: relative;
  transition: transform 0.5s ease;
  transform: ${(props) => (props.open ? "translateX(250px)" : "translateX(0)")};
`;

class HubScaffold extends Component {
  constructor(props) {
    super(props);
    this.state = { mainSideMenuOpen: false };

    this.menuToggeled = this.menuToggeled.bind(this);
    this.logoutUser = this.logoutUser.bind(this);
  }

  menuToggeled() {
    this.setState((state) => ({ mainSideMenuOpen: !state.mainSideMenuOpen }));
  }

  logoutUser() {
    this.props.context.logout();
  }

  renderLink(link) {
    if (link.external) {
      return (
        <LinkList.Item href={link.url} key={link.url} as="a">
          {link.name}
        </LinkList.Item>
      );
    }

    return (
      <LinkList.Item to={link.url} key={link.url}>
        {link.name}
      </LinkList.Item>
    );
  }

  renderLinkSidebar(link) {
    if (link.external) {
      return (
        <div key={link.url}>
          <Action href={link.url} as="a" onClick={this.menuToggeled}>
            {link.name}
          </Action>
        </div>
      );
    }

    return (
      <div key={link.url}>
        <Action to={link.url} as={NavLink} onClick={this.menuToggeled}>
          {link.name}
        </Action>
      </div>
    );
  }

  render() {
    const {
      context: {
        state: { user },
      },
    } = this.props;

    return (
      <>
        <HubScaffoldStyled open={this.state.mainSideMenuOpen}>
          <Header>
            <Section>
              <HeaderBar>
                <MenuButton onClick={this.menuToggeled} />
              </HeaderBar>
              <HeaderBarExtended>
                <Logo>
                  <LogoLink to="/">
                    <img alt="tyg" src={this.props.logo} />
                  </LogoLink>
                </Logo>
                <LinkList inline>
                  {this.props.links.map((link) => this.renderLink(link))}
                  {!user.isloggedin ? (
                    <Action buttonStyle={true} to="/login" as={NavLink}>
                      Login
                    </Action>
                  ) : (
                    <Action buttonStyle onClick={this.logoutUser}>
                      Logout
                    </Action>
                  )}
                </LinkList>
              </HeaderBarExtended>
            </Section>
          </Header>

          {this.props.children}

          <Footer>
            <Section>
              <FooterInfo>
                <FooterInfoSection>
                  <PropertyItem>
                    <Typo size="1.2rem">Servicestelle Gemeinschaftsgärten</Typo>
                  </PropertyItem>
                  <PropertyItem>
                    <Typo margin="1rem 0">
                      Euer Partner rund um das Thema Gemeinschaftsgärten und
                      Essbare Gemeinden!
                    </Typo>
                  </PropertyItem>
                </FooterInfoSection>
                <FooterInfoSection>
                  <Typo margin="0 0 1rem 0">
                    <PropertyItem>
                      <div>gemeinschaftsgaerten@tsn.at</div>
                    </PropertyItem>
                    <PropertyItem>
                      <div>0664-88467006</div>
                    </PropertyItem>
                  </Typo>
                  <div>
                    <Action href="http://eepurl.com/cl9ZEP" as="a">
                      Newsletter abonieren <ChevronRight size="16px" />
                    </Action>
                  </div>
                  <div>
                    <Action to="/impressum" as={NavLink}>
                      Impressum <ChevronRight size="16px" />
                    </Action>
                  </div>
                </FooterInfoSection>
                <FooterInfoSection>
                  <Typo margin="0 0 1rem 0">
                    Ein Service des Tiroler Bildungsforums
                  </Typo>
                  <TbfLogoStyled href="https://tiroler-bildungsforum.at/" alt="Tiroler Bildungsforum" target="_blank">
                    <Image src={TbfLogo}></Image>
                  </TbfLogoStyled>
                </FooterInfoSection>
              </FooterInfo>
            </Section>
          </Footer>
        </HubScaffoldStyled>

        <SideBar open={this.state.mainSideMenuOpen}>
          <Logo>
            <LogoLink to="/" onClick={this.menuToggeled}>
              <img alt="tyg" src={this.props.logo} />
            </LogoLink>
          </Logo>
          <LinkList>
            {this.props.links.map((link) => this.renderLinkSidebar(link))}
            
            {!user.isloggedin ? (
              <LoginButtonContainerMobile>
                <Action
                  buttonStyle={true}
                  to="/login"
                  as={NavLink}
                  onClick={this.menuToggeled}
                >
                  Login
                </Action>
              </LoginButtonContainerMobile>
            ) : (
              <LoginButtonContainerMobile>
                <Action buttonStyle onClick={this.logoutUser}>
                  Logout
                </Action>
              </LoginButtonContainerMobile>
            )}
          </LinkList>
        </SideBar>
      </>
    );
  }
}

export default Subscribe(HubScaffold, { context: HubStateContainer });

/*
< LinkList.Divider inline />
<ActionRouterLink text="true" to="/login">Login</ActionRouterLink>
*/
