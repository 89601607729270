import React from "react";
import ReactDOM from "react-dom";
import { BrowserRouter } from "react-router-dom";

import HubContext from "./HubContext";

ReactDOM.render(
  <BrowserRouter>
    <HubContext />
  </BrowserRouter>,
  document.getElementById("Hub")
);
