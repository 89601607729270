import styled from "styled-components";

const ActionList = styled.div`
  display: flex;
  flex-direction: row;
  align-items: center;
  justify-content: ${props => (props.secondary ? "flex-end" : "flex-start")};
  border-top: ${props => (props.noBorder ? "none" : "1px solid #b3b3b3")};
  > * {
    margin-right: 0.8rem;
  }
`;

export default ActionList;
